import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {
    CustomDoubleCellEditor,
    CustomGroupInvoiceDaysUntilDueCellEditor,
    CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor,
    editNameIconOnlyCellRenderer
} from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useForm} from "react-hook-form";
import {Button, FormControlLabel, IconButton, Switch, ThemeProvider} from "@mui/material";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput,
    MuiCloseIconButton, MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {
    ccChangeDistributorContactReactive,
    ccChangeGroupCanCreatePOCReactive,
    ccChangeGroupDaysUntilInvoiceDueReactive,
    ccChangeGroupDistributorDiscountReactive,
    ccChangeGroupIsDistributorReactive,
    ccChangeGroupIsReferralAgentReactive,
    ccChangeGroupIsResellerReactive, ccChangeGroupMaxDaysUntilTrialLicensesExpireReactive,
    ccChangeGroupReferralDiscountReactive,
    ccChangeGroupResellerDiscountReactive, ccCreateNewPartnerGroupReactive,
    ccGetAllNonChannelPartnerGroupsReactive,
    ccGetUsersInGroupReactive,
    ccPromoteGroupToPartnerReactive,
    ccTurnOffChannelPartnerSettingForGroupReactive
} from "../api/groupsApi";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConfirmationModal} from "../../components/confirmationModal";
import {getItemFromStorageWithoutDecrypting} from "../../utils/storageHelper";
import {ccRetrieveUsernamesReactive} from "../api/notificationsApi";
import * as EmailValidator from "email-validator";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {changeLicenseNameReactive} from "../api/licensesApi";

export const minDaysUntilInvoiceDue = 1
export const minDaysForTrialLicenseExpiration = 1
export const maxDaysUntilInvoiceDue = 180
export const maxDaysUntilTrialLicenseExpiration = 90

export default function CcOnlyPartnerGroupManagement() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [nonPartnerGroups, setNonPartnerGroups] = useState([]);
    const [overrideTurnChannelPartnerOffConfirmModalIsOpen, setOverrideTurnChannelPartnerOffConfirmModalIsOpen] = useState(false);
    const [gridParamsForConfirmTurnChannelPartnerOff, setGridParamsForConfirmTurnChannelPartnerOff] = useState();
    const [overrideIsResellerConfirmModalIsOpen, setOverrideIsResellerConfirmModalIsOpen] = useState(false);
    const [overrideIsDistributorConfirmModalIsOpen, setOverrideIsDistributorConfirmModalIsOpen] = useState(false);
    const [overrideIsReferralAgentConfirmModalIsOpen, setOverrideIsReferralAgentConfirmModalIsOpen] = useState(false);
    const [overrideDistributorDiscountModalIsOpen, setOverrideDistributorDiscountModalIsOpen] = useState(false);
    const [overrideResellerDiscountModalIsOpen, setOverrideResellerDiscountModalIsOpen] = useState(false);
    const [overrideReferralDiscountModalIsOpen, setOverrideReferralDiscountModalIsOpen] = useState(false);
    const [overridePartnerTypeZenGroupData, setOverridePartnerTypeZenGroupData] = useState();
    const [overrideDiscountParams, setOverrideDiscountParams] = useState();
    const [overrideDiscountNewValue, setOverrideDiscountNewValue] = useState();
    const [promoteModalIsOpen, setPromotePromoteModalIsOpen] = useState(false);
    const [newPartnerGroupModalIsOpen, setNewPartnerGroupModalIsOpen] = useState(false);
    const [allUsernamesQueriedAlready, setAllUsernamesQueriedAlready] = useState(false);
    const [allUsernamesList, setAllUsernamesList] = useState([]);
    const [newPartnerGroupOwnerModalValue, setNewPartnerGroupOwnerModalValue] = useState(null);
    const [distributorContactModalValue, setDistributorContactModalValue] = useState(null);
    const [zenGroupSelectedToPromoteToPartner, setZenGroupSelectedToPromoteToPartner] = useState();
    const [modalIsDistributor, setModalIsDistributor] = useState(false);
    const [modalIsReseller, setModalIsReseller] = useState(false);
    const [modalIsReferralAgent, setModalIsReferralAgent] = useState(false);
    const [modalCanCreatePOC, setModalCanCreatePOC] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(1000);
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "zenGroupDisplayName", initialWidth: 330, headerName: "Group Name", filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true,
            cellRenderer: 'agGroupCellRenderer',
            comparator: function (valueA, valueB) {
                if((valueA === null || valueA === undefined) && (valueB === null || valueB === undefined)){ //if both null
                    return 0
                }
                else if(valueA === null || valueA === undefined){ //both are not null, but valueA is null, return 1 to sort valueA after valueB
                    return 1
                }
                else if(valueB === null || valueB === undefined){ //both are not null, but valueB is null, return -1 to sort valueA before valueB
                    return -1
                }
                else{
                    //else compare both lowercase values
                    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
                }
            }
        },
        { field: "organizationName", headerName: "Organization Name", sortable: true, initialWidth: 325, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
        },
        { field: "channelPartner", headerName: "Channel Partner", initialWidth: 220, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner', 'Direct Customer'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.channelPartner){
                        return "Channel Partner";
                    }else{
                        return "Direct Customer";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.channelPartner){
                    return "Channel Partner";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartner}
                                    name={`cellToggleChannelPartner${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    disabled={!params.node.data.channelPartner}
                                    onChange={e => {
                                        setIsLoading(true)
                                        ccTurnOffChannelPartnerSettingForGroupReactive(params.node.data.id, false).then(response => {
                                            setIsLoading(false)
                                            let requiresOverride = response.data.requiresOverride
                                            if(requiresOverride){
                                                setGridParamsForConfirmTurnChannelPartnerOff(params)
                                                setOverridePartnerTypeZenGroupData(params.node.data)
                                                setOverrideTurnChannelPartnerOffConfirmModalIsOpen(true)
                                            }
                                            else{
                                                //else no override required
                                                //Change stream will not trigger since turning channelPartner off and cs listens for groups with channelPartner true, need to set the other fields to false
                                                params.node.setDataValue("channelPartner", false)
                                                params.node.setDataValue("isDistributor", false)
                                                params.node.setDataValue("isReseller", false)
                                                params.node.setDataValue("isReferralAgent", false)
                                                params.node.setDataValue("canCreatePOC", false)
                                            }
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                            setIsLoading(false)
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "isDistributor", headerName: "Is a Distributor", initialWidth: 220, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Distributor', 'Not a Distributor'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.isDistributor){
                        return "Distributor";
                    }else{
                        return "Not a Distributor";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.isDistributor){
                    return "Distributor";
                }else{
                    return "Not a Distributor";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.isDistributor}
                                    name={`cellToggleIsADistributor${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    onChange={e => {
                                        //We should use loading overlay since they might need to click override
                                        setIsLoading(true)
                                        ccChangeGroupIsDistributorReactive(params.node.data.id, e.target.checked, false).then(response => {
                                            //Change stream will update the value
                                            setIsLoading(false)
                                            let requiresOverride = response.data.requiresOverride
                                            if(requiresOverride){
                                                setOverridePartnerTypeZenGroupData(params.node.data)
                                                setOverrideIsDistributorConfirmModalIsOpen(true)
                                            }
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                            setIsLoading(false)
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "distributorContact", headerName: "Distributor Point of Contact", sortable: true,
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to edit the distributor point of contact used for any distributor's reseller deal's as the stripe customer. The" +
                    " user will be added to the group, and an account will be made if one does not exist yet", "distributorContact", true)
            }
        },
        { field: "isReseller", headerName: "Is a Reseller", initialWidth: 200, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Reseller', 'Not a Reseller'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.isReseller){
                        return "Reseller";
                    }else{
                        return "Not a Reseller";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.isReseller){
                    return "Reseller";
                }else{
                    return "Not a Reseller";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.isReseller}
                                    name={`cellToggleIsAReseller${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    onChange={e => {
                                        //We should use loading overlay since they might need to click override
                                        setIsLoading(true)
                                        ccChangeGroupIsResellerReactive(params.node.data.id, e.target.checked, false).then(response => {
                                            //Change stream will update the value
                                            setIsLoading(false)
                                            let requiresOverride = response.data.requiresOverride
                                            if(requiresOverride){
                                                setOverridePartnerTypeZenGroupData(params.node.data)
                                                setOverrideIsResellerConfirmModalIsOpen(true)
                                            }
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                            setIsLoading(false)
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "isReferralAgent", headerName: "Is a Referral Agent", initialWidth: 240, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Referral Agent', 'Not a Referral Agent'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.isReferralAgent){
                        return "Referral Agent";
                    }else{
                        return "Not a Referral Agent";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.isReferralAgent){
                    return "Referral Agent";
                }else{
                    return "Not a Referral Agent";
                }
            },
            cellRenderer: function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.isReferralAgent}
                                    name={`cellToggleIsAReferralAgent${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    onChange={e => {
                                        //We should use loading overlay since they might need to click override
                                        setIsLoading(true)
                                        ccChangeGroupIsReferralAgentReactive(params.node.data.id, e.target.checked, false).then(response => {
                                            //Change stream will update the value
                                            setIsLoading(false)
                                            let requiresOverride = response.data.requiresOverride
                                            if(requiresOverride){
                                                setOverridePartnerTypeZenGroupData(params.node.data)
                                                setOverrideIsReferralAgentConfirmModalIsOpen(true)
                                            }
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                            setIsLoading(false)
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "canCreatePOC", headerName: "Can Create POC", initialWidth: 225, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Can Create POC', 'Cannot Create POC'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.canCreatePOC){
                        return "Can Create POC";
                    }else{
                        return "Cannot Create POC";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.canCreatePOC){
                    return "Can Create POC";
                }else{
                    return "Cannot Create POC";
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.canCreatePOC}
                                name={`cellToggleCanCreatePOC${params.node.data.id}`}
                                className={"cursor-pointer"}
                                onChange={e => {
                                    //We should use loading overlay since they might need to click override
                                    setIsLoading(true)
                                    ccChangeGroupCanCreatePOCReactive(params.node.data.id, e.target.checked).then(response => {
                                        //Change stream will update the value
                                        setIsLoading(false)
                                    }).catch(error => {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                        setIsLoading(false)
                                    })
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            sortable: true
        },
        { field: "distributorDiscount", headerName: "Distributor Discount %", sortable: true, initialWidth: 235, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            valueFormatter: function(params){
                if(params.value !== null && params.value !== undefined){
                    return `${params.value}%`
                }
            },
            cellRenderer: function (params) {
                    return (
                        <div className={`flex flex-row items-center gap-x-1`}>
                            <MuiIconButtonWithTooltip
                                icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                                onClick={() => {
                                    //manually start editing the cell
                                    if(params.api && params.node){
                                        params.api.startEditingCell({
                                            rowIndex: params.node.rowIndex,
                                            colKey: "distributorDiscount"
                                        })
                                    }
                                }}
                                tooltipTitle={"Click to edit the distributor discount"}
                                tooltipPlacement={"bottom-start"}
                            />
                            {`${params.value}%`}
                        </div>
                    )
            },
            editable: true,
            cellEditor: "customDoubleCellEditor"
        },
        { field: "resellerDiscount", headerName: "Reseller Discount %", sortable: true, initialWidth: 225, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            valueFormatter: function(params){
                if(params.value !== null && params.value !== undefined){
                    return `${params.value}%`
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "resellerDiscount"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the reseller discount"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {`${params.value}%`}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customDoubleCellEditor"
        },
        { field: "referralDiscount", headerName: "Referral Discount %", sortable: true, initialWidth: 225, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            valueFormatter: function(params){
                if(params.value !== null && params.value !== undefined){
                    return `${params.value}%`
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "referralDiscount"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the referral discount"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {`${params.value}%`}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customDoubleCellEditor"
        },
        { field: "partnerInvoiceDaysUntilDue", headerName: "Days Until Invoices are Due", sortable: true, initialWidth: 300, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "partnerInvoiceDaysUntilDue"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the days until partner deal invoices are due for this group"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customGroupInvoiceDaysUntilDueCellEditor"
        },
        { field: "partnerMaxDaysForPOCTrialLicensesToExpire", headerName: "Max Days Until POC Trial Licenses Can Expire", sortable: true, initialWidth: 400, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "partnerMaxDaysForPOCTrialLicensesToExpire"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the max days a partner can set POC trial licenses to expire when creating a POC group"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor"
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            floatingFilter: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(375)
    }, []);
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            ccGetAllNonChannelPartnerGroupsReactive().then(response => {
                response.sort((object1, object2) => (object1.name?.toLowerCase() > object2.name?.toLowerCase()) ? 1 : -1)
                setNonPartnerGroups(response)
            }).catch(error => {})
        })()
        return () => controller?.abort();
    }, []);

    const submitPromoteToPartnerGroup = (data) => {
        if(!zenGroupSelectedToPromoteToPartner){
            NotificationManager.info("You must select a group")
            return
        }
        if(!modalIsDistributor && !modalIsReseller && !modalIsReferralAgent){
            NotificationManager.info("You must turn at least one of the distributor, reseller, or referral agent settings on")
            return;
        }
        if(!data){
            NotificationManager.error("Unexpected error making this request");
            return;
        }
        let distributorContactToSend = null
        if(modalIsDistributor){
            if(distributorContactModalValue === null || distributorContactModalValue === undefined){
                NotificationManager.info("Fill out the distributor contact for this distributor group");
                return;
            }
            //verify group owner username/email
            if(!EmailValidator.validate(distributorContactModalValue.trim().toLowerCase())){
                NotificationManager.error("Please enter a valid email address for the distributor point of contact email")
                return;
            }
            distributorContactToSend = distributorContactModalValue.trim().toLowerCase()
        }

        setIsLoading(true)
        ccPromoteGroupToPartnerReactive(zenGroupSelectedToPromoteToPartner, modalIsDistributor, modalIsReseller, modalIsReferralAgent, data.distributorDiscount, data.resellerDiscount,
            data.referralAgentDiscount, data.daysUntilDealInvoicesDue, modalCanCreatePOC, data.partnerMaxDaysForPOCTrialLicensesToExpire, distributorContactToSend).then(response => {
                setIsLoading(false)
                NotificationManager.success("Successfully promoted this group to a partner")
                resetModalFields()
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error making this request");
            }
            setIsLoading(false)
        })

    };

    const submitNewPartnerGroup = (data) => {
        if(!data){
            NotificationManager.error("Unexpected error making this request");
            return;
        }
        if(data.newPartnerGroupName === null || data.newPartnerGroupName === undefined || data.newPartnerGroupName.trim().length < 1){
            NotificationManager.info("You must enter a group name for this partner group")
            return;
        }
        if(newPartnerGroupOwnerModalValue === null || newPartnerGroupOwnerModalValue === undefined){
            NotificationManager.info("You must enter a group owner for this partner group")
            return;
        }
        //verify group owner username/email
        if(!EmailValidator.validate(newPartnerGroupOwnerModalValue.trim().toLowerCase())){
            NotificationManager.error("Please enter a valid email address for the group owner")
            return;
        }
        if(!modalIsDistributor && !modalIsReseller && !modalIsReferralAgent){
            NotificationManager.info("You must turn at least one of the distributor, reseller, or referral agent settings on")
            return;
        }
        let distributorContactToSend = null
        if(modalIsDistributor){
            if(distributorContactModalValue === null || distributorContactModalValue === undefined){
                NotificationManager.info("Fill out the distributor contact for this distributor group");
                return;
            }
            //verify group owner username/email
            if(!EmailValidator.validate(distributorContactModalValue.trim().toLowerCase())){
                NotificationManager.error("Please enter a valid email address for the distributor point of contact email")
                return;
            }
            distributorContactToSend = distributorContactModalValue.trim().toLowerCase()
        }
        setIsLoading(true)
        ccCreateNewPartnerGroupReactive(data.newPartnerGroupName.trim(), newPartnerGroupOwnerModalValue.trim().toLowerCase(), modalIsDistributor, modalIsReseller, modalIsReferralAgent,
            data.distributorDiscount, data.resellerDiscount, data.referralAgentDiscount, data.daysUntilDealInvoicesDue, modalCanCreatePOC, data.partnerMaxDaysForPOCTrialLicensesToExpire,
            distributorContactToSend).then(response => {
            setIsLoading(false)
            NotificationManager.success("Successfully created new partner group")
            resetModalFields()
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error making this request");
            }
            setIsLoading(false)
        })
    };

    function resetModalFields(){
        setPromotePromoteModalIsOpen(false)
        setNewPartnerGroupModalIsOpen(false)
        setNewPartnerGroupOwnerModalValue(null)
        setDistributorContactModalValue(null)
        setZenGroupSelectedToPromoteToPartner()
        setModalIsDistributor(false)
        setModalIsReseller(false)
        setModalIsReferralAgent(false)
        setModalCanCreatePOC(false)
        reset({
            distributorDiscount: "40.00",
            resellerDiscount: "30.00",
            referralAgentDiscount: "10.00",
            daysUntilDealInvoicesDue: "30",
            partnerMaxDaysForPOCTrialLicensesToExpire: "14",
            newPartnerGroupName: "",
            distributorContact: ""
        })
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Cyber Crucible Only Partner Group Management</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Promote Channel Partner Group"
                   isOpen={promoteModalIsOpen}
                   onRequestClose={() => {
                       resetModalFields()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitPromoteToPartnerGroup)}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Promote Channel Partner Group</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetModalFields()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="">
                            <label>Select which group to promote to channel partner</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={nonPartnerGroups.map(({ zenGroupId, name, organizationName }) => ({
                                    value: zenGroupId,
                                    label: `${name || zenGroupId} ${organizationName ? `(${organizationName})` : ""}`,
                                }))}
                                value={zenGroupSelectedToPromoteToPartner}
                                onChange={( event, value ) => {
                                    setZenGroupSelectedToPromoteToPartner(value?.value)
                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsDistributor}
                                        name="modalIsDistributorToggle"
                                        onChange={e => setModalIsDistributor(e.target.checked)}
                                    />
                                } label={modalIsDistributor ? "Is a Distributor" : "Is Not a Distributor"}/>
                            </ThemeProvider>
                        </div>
                        {modalIsDistributor && (
                            <div>
                                <label>Distributor Email to use for their Reseller Group Deals' as the Stripe Customer.
                                    User will be added to the group, and an account will be created if the user does not
                                    exist yet</label>
                                <MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput
                                    label={"Distributor Point of Contact Email Address"}
                                    options={allUsernamesList}
                                    value={distributorContactModalValue}
                                    onInputChange={(event, value) => {
                                        setDistributorContactModalValue(value)
                                    }}
                                />
                            </div>
                        )}
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReseller}
                                        name="modalIsResellerToggle"
                                        onChange={e => setModalIsReseller(e.target.checked)}
                                    />
                                } label={modalIsReseller ? "Is a Reseller" : "Is Not a Reseller"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReferralAgent}
                                        name="modalIsReferralAgentToggle"
                                        onChange={e => setModalIsReferralAgent(e.target.checked)}
                                    />
                                } label={modalIsReferralAgent ? "Is a Referral Agent" : "Is Not a Referral Agent"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalCanCreatePOC}
                                        name="modalCanCreatePOCToggle"
                                        onChange={e => setModalCanCreatePOC(e.target.checked)}
                                    />
                                } label={modalCanCreatePOC ? "Can Setup Their Own POCs" : "Cannot Setup Their Own POCs"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`w-full`}>
                            <label>Distributor Discount Percentage (Default 40%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="distributorDiscount"
                                {...register("distributorDiscount")}
                                placeholder="Default Discount is 40.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Reseller Discount Percentage (Default 30%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="resellerDiscount"
                                {...register("resellerDiscount")}
                                placeholder="Default Discount is 30.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Referral Agent Discount Percentage (Default 10%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="referralAgentDiscount"
                                {...register("referralAgentDiscount")}
                                placeholder="Default Discount is 10.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Days Until Partner Deal Invoices Due (Default 30)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysUntilInvoiceDue} max={maxDaysUntilInvoiceDue} step={1}
                                type="number" name="daysUntilDealInvoicesDue"
                                {...register("daysUntilDealInvoicesDue")}
                                placeholder="Default is 30 Days"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Max Days Partners Can Set POC Trial Licenses to Expire (Default 14)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysForTrialLicenseExpiration} max={maxDaysUntilTrialLicenseExpiration} step={1}
                                type="number" name="partnerMaxDaysForPOCTrialLicensesToExpire"
                                {...register("partnerMaxDaysForPOCTrialLicensesToExpire")}
                                placeholder="Default is 14 Days"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}>
                                    Promote to Channel Partner
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal contentLabel="Create New Channel Partner Group"
                   isOpen={newPartnerGroupModalIsOpen}
                   onRequestClose={() => {
                       resetModalFields()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitNewPartnerGroup)}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Create New Channel Partner Group</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetModalFields()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="flex flex-col gap-y-2">
                            <label>Partner Group Name</label>
                            <input
                                type="text"
                                name="newPartnerGroupName"
                                {...register("newPartnerGroupName")}
                                className="focus:outline-none h-10 p-2 w-full rounded-lg border border-black border-opacity-25 border-solid"
                                placeholder={'Partner Group Name'}
                            />
                        </div>
                        <div>
                            <label>Group Owner Username</label>
                            <MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput
                                label={"User will be created if they do not have an account yet"}
                                options={allUsernamesList}
                                value={newPartnerGroupOwnerModalValue}
                                onInputChange={(event, value) => {
                                    setNewPartnerGroupOwnerModalValue(value)
                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsDistributor}
                                        name="newPartnerGroupModalIsDistributorToggle"
                                        onChange={e => setModalIsDistributor(e.target.checked)}
                                    />
                                } label={modalIsDistributor ? "Is a Distributor" : "Is Not a Distributor"}/>
                            </ThemeProvider>
                        </div>
                        {modalIsDistributor && (
                            <div>
                                <label>Distributor Email to use for their Reseller Group Deals' as the Stripe Customer. User will be added to the group, and an account will be created if the user does not exist yet</label>
                                <MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput
                                    label={"Distributor Point of Contact Email Address"}
                                    options={allUsernamesList}
                                    value={distributorContactModalValue}
                                    onInputChange={(event, value) => {
                                        setDistributorContactModalValue(value)
                                    }}
                                />
                            </div>
                        )}
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReseller}
                                        name="newPartnerGroupModalIsResellerToggle"
                                        onChange={e => setModalIsReseller(e.target.checked)}
                                    />
                                } label={modalIsReseller ? "Is a Reseller" : "Is Not a Reseller"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReferralAgent}
                                        name="newPartnerGroupModalIsReferralAgentToggle"
                                        onChange={e => setModalIsReferralAgent(e.target.checked)}
                                    />
                                } label={modalIsReferralAgent ? "Is a Referral Agent" : "Is Not a Referral Agent"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalCanCreatePOC}
                                        name="newPartnerGroupModalCanCreatePOCToggle"
                                        onChange={e => setModalCanCreatePOC(e.target.checked)}
                                    />
                                } label={modalCanCreatePOC ? "Can Setup Their Own POCs" : "Cannot Setup Their Own POCs"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`w-full`}>
                            <label>Distributor Discount Percentage (Default 40%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="distributorDiscount"
                                {...register("distributorDiscount")}
                                placeholder="Default Discount is 40.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Reseller Discount Percentage (Default 30%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="resellerDiscount"
                                {...register("resellerDiscount")}
                                placeholder="Default Discount is 30.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Referral Agent Discount Percentage (Default 10%)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01}
                                type="number" name="referralAgentDiscount"
                                {...register("referralAgentDiscount")}
                                placeholder="Default Discount is 10.00%"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Days Until Partner Deal Invoices Due (Default 30)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysUntilInvoiceDue} max={maxDaysUntilInvoiceDue} step={1}
                                type="number" name="daysUntilDealInvoicesDue"
                                {...register("daysUntilDealInvoicesDue")}
                                placeholder="Default is 30 Days"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Max Days Partners Can Set POC Trial Licenses to Expire (Default 14)</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysForTrialLicenseExpiration} max={maxDaysUntilTrialLicenseExpiration} step={1}
                                type="number" name="partnerMaxDaysForPOCTrialLicensesToExpire"
                                {...register("partnerMaxDaysForPOCTrialLicensesToExpire")}
                                placeholder="Default is 14 Days"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}>
                                    Create New Partner Group
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <ConfirmationModal
                text={`There is a partner deal already registered with group "${overridePartnerTypeZenGroupData?.zenGroupDisplayName}". Demoting a group to not be a partner anymore revokes 
                 the Partner Portal access for users in this group. Please confirm the desired action is to set this group as not a partner group.`}
                onConfirm={() => {
                    if(overridePartnerTypeZenGroupData && overridePartnerTypeZenGroupData.id) {
                        setIsLoading(true)
                        ccTurnOffChannelPartnerSettingForGroupReactive(overridePartnerTypeZenGroupData.id, true).then(response => {
                            setIsLoading(false)
                            setOverridePartnerTypeZenGroupData()
                            setOverrideTurnChannelPartnerOffConfirmModalIsOpen(false)
                            //Change stream will not trigger since turning channelPartner off and cs listens for groups with channelPartner true, need to set the other fields to false
                            gridParamsForConfirmTurnChannelPartnerOff && gridParamsForConfirmTurnChannelPartnerOff.node.setDataValue("channelPartner", false)
                            gridParamsForConfirmTurnChannelPartnerOff && gridParamsForConfirmTurnChannelPartnerOff.node.setDataValue("isDistributor", false)
                            gridParamsForConfirmTurnChannelPartnerOff && gridParamsForConfirmTurnChannelPartnerOff.node.setDataValue("isReseller", false)
                            gridParamsForConfirmTurnChannelPartnerOff && gridParamsForConfirmTurnChannelPartnerOff.node.setDataValue("isReferralAgent", false)
                            gridParamsForConfirmTurnChannelPartnerOff && gridParamsForConfirmTurnChannelPartnerOff.node.setDataValue("canCreatePOC", false)
                            setGridParamsForConfirmTurnChannelPartnerOff() //reset
                        }).catch(error => {
                            if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error("Unexpected error making this request");
                            }
                            setIsLoading(false)
                        })
                    }
                }}
                onClose={() => {
                    setOverridePartnerTypeZenGroupData()
                    setOverrideTurnChannelPartnerOffConfirmModalIsOpen(false)
                    setGridParamsForConfirmTurnChannelPartnerOff()
                }}
                opened={overrideTurnChannelPartnerOffConfirmModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overridePartnerTypeZenGroupData?.zenGroupDisplayName}" has a partner deal registered with the related prospect type for this setting. Please confirm the desired action 
                is to turn this setting off, only future deals registered with this group will be effected.`}
                onConfirm={() => {
                    changePartnerSettingHelper(ccChangeGroupIsDistributorReactive)
                }}
                onClose={() => {
                    setOverridePartnerTypeZenGroupData()
                    setOverrideIsResellerConfirmModalIsOpen(false)
                    setOverrideIsDistributorConfirmModalIsOpen(false)
                    setOverrideIsReferralAgentConfirmModalIsOpen(false)
                }}
                opened={overrideIsDistributorConfirmModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overridePartnerTypeZenGroupData?.zenGroupDisplayName}" has a partner deal registered with the related prospect type for this setting. Please confirm the desired action 
                is to turn this setting off, only future deals registered with this group will be effected.`}
                onConfirm={() => {
                    changePartnerSettingHelper(ccChangeGroupIsResellerReactive)
                }}
                onClose={() => {
                    setOverridePartnerTypeZenGroupData()
                    setOverrideIsResellerConfirmModalIsOpen(false)
                }}
                opened={overrideIsResellerConfirmModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overridePartnerTypeZenGroupData?.zenGroupDisplayName}" has a partner deal registered with the related prospect type for this setting. Please confirm the desired action 
                is to turn this setting off, only future deals registered with this group will be effected.`}
                onConfirm={() => {
                    changePartnerSettingHelper(ccChangeGroupIsReferralAgentReactive)
                }}
                onClose={() => {
                    setOverridePartnerTypeZenGroupData()
                    setOverrideIsResellerConfirmModalIsOpen(false)
                    setOverrideIsDistributorConfirmModalIsOpen(false)
                    setOverrideIsReferralAgentConfirmModalIsOpen(false)
                }}
                opened={overrideIsReferralAgentConfirmModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overrideDiscountParams?.node?.data?.zenGroupDisplayName}" currently has a request out for a quote to be signed and finalized for a Distributor Deal that has not been completed yet. 
                You will have to manually remedy this situation with the new Distributor Discount for this group if you click Confirm`}
                onConfirm={() => {
                    handleChangePartnerDiscountHelper(ccChangeGroupDistributorDiscountReactive)
                }}
                onClose={() => {
                    setOverrideDiscountParams()
                    setOverrideDistributorDiscountModalIsOpen(false)
                    setOverrideResellerDiscountModalIsOpen(false)
                    setOverrideReferralDiscountModalIsOpen(false)
                    setOverrideDiscountNewValue()
                }}
                opened={overrideDistributorDiscountModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overrideDiscountParams?.node?.data?.zenGroupDisplayName}" currently has a request out for a quote to be signed and finalized for a Reseller Deal that has not been completed yet. 
                You will have to manually remedy this situation with the new Reseller Discount for this group if you click Confirm`}
                onConfirm={() => {
                    handleChangePartnerDiscountHelper(ccChangeGroupResellerDiscountReactive)
                }}
                onClose={() => {
                    setOverrideDiscountParams()
                    setOverrideDistributorDiscountModalIsOpen(false)
                    setOverrideResellerDiscountModalIsOpen(false)
                    setOverrideReferralDiscountModalIsOpen(false)
                    setOverrideDiscountNewValue()
                }}
                opened={overrideResellerDiscountModalIsOpen}
            />
            <ConfirmationModal
                text={`Group "${overrideDiscountParams?.node?.data?.zenGroupDisplayName}" currently has a request out for a quote to be signed and finalized for a Referral Deal that has not been completed yet. 
                You will have to manually remedy this situation with the new Referral Discount for this group if you click Confirm`}
                onConfirm={() => {
                    handleChangePartnerDiscountHelper(ccChangeGroupReferralDiscountReactive)
                }}
                onClose={() => {
                    setOverrideDiscountParams()
                    setOverrideDistributorDiscountModalIsOpen(false)
                    setOverrideResellerDiscountModalIsOpen(false)
                    setOverrideReferralDiscountModalIsOpen(false)
                    setOverrideDiscountNewValue()
                }}
                opened={overrideReferralDiscountModalIsOpen}
            />
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Cyber Crucible Only Partner Group Management")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={
                                    <IconButton
                                        sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}
                                    >
                                        <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-arrow-up"} size="sm" color={`black`} />
                                    </IconButton>
                                }
                                tooltipTitle={"Promote Group to Partner Group"} tooltipPlacement={"top"}
                                onClick={() => {
                                    resetModalFields() //reset before opening modal
                                    setPromotePromoteModalIsOpen(true)
                                    //Check if we already queried for usernames
                                    let userName = getItemFromStorageWithoutDecrypting("username")
                                    if(!allUsernamesQueriedAlready) {
                                        setAllUsernamesQueriedAlready(true)
                                        if (userName.endsWith("@cybercrucible.com")) {
                                            ccRetrieveUsernamesReactive().then(response => {
                                                setAllUsernamesList(response)
                                            }).catch(function (error) {
                                                setAllUsernamesList([])
                                            })
                                        }
                                    }
                                }}
                            />
                            <MuiIconButtonWithTooltipAndBox
                                icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>}
                                tooltipTitle={"Create Partner Group"} tooltipPlacement={"top"}
                                onClick={() => {
                                    resetModalFields() //reset before opening modal
                                    setNewPartnerGroupModalIsOpen(true)
                                    //Check if we already queried for usernames
                                    let userName = getItemFromStorageWithoutDecrypting("username")
                                    if(!allUsernamesQueriedAlready) {
                                        setAllUsernamesQueriedAlready(true)
                                        if (userName.endsWith("@cybercrucible.com")) {
                                            ccRetrieveUsernamesReactive().then(response => {
                                                setAllUsernamesList(response)
                                            }).catch(function (error) {
                                                setAllUsernamesList([])
                                            })
                                        }
                                    }
                                }}
                            />
                        </div>
                        <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                      excelExportFunction = {excelExport}/>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                            setOverrideDistributorDiscountModalIsOpen={setOverrideDistributorDiscountModalIsOpen}
                            setOverrideResellerDiscountModalIsOpen={setOverrideResellerDiscountModalIsOpen}
                            setOverrideReferralDiscountModalIsOpen={setOverrideReferralDiscountModalIsOpen}
                            setOverrideDiscountParams={setOverrideDiscountParams}
                            setOverrideDiscountNewValue={setOverrideDiscountNewValue}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "partnerGroupsGridExport")
    }

    function changePartnerSettingHelper(apiFunction){
        if(overridePartnerTypeZenGroupData && overridePartnerTypeZenGroupData.id && apiFunction) {
            //This modal should only be opened if turning the setting off, send false for newSetting and true to override
            setIsLoading(true)
            apiFunction(overridePartnerTypeZenGroupData.id, false, true).then(response => {
                //Change stream will update the value
                setOverridePartnerTypeZenGroupData()
                setOverrideIsResellerConfirmModalIsOpen(false)
                setOverrideIsDistributorConfirmModalIsOpen(false)
                setOverrideIsReferralAgentConfirmModalIsOpen(false)
                setIsLoading(false)
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    }

    function handleChangePartnerDiscountHelper(apiFunction){
        if(overrideDiscountParams && overrideDiscountParams.node && overrideDiscountParams.node.data && overrideDiscountNewValue && apiFunction) {
            setIsLoading(true)
            apiFunction(overrideDiscountParams.node.data.id, overrideDiscountNewValue, true).then(response => {
                //Let cs update grid
                setIsLoading(false)
                setOverrideDiscountParams()
                setOverrideDistributorDiscountModalIsOpen(false)
                setOverrideResellerDiscountModalIsOpen(false)
                setOverrideReferralDiscountModalIsOpen(false)
                setOverrideDiscountNewValue()
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
        if(event.column.colId === "distributorDiscount"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                //event.data.distributorDiscount = oldValue
                //gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: false, force: true, rowNodes: [event.node]})
                return;
            }
            //validate newValue is between 0-100
            try{
                let parseFloatValue = parseFloat(newValue).toFixed(2)
                if(isNaN(parseFloatValue) || parseFloatValue === null){
                    event.data.distributorDiscount = oldValue
                    gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseFloatValue
                }
            }
            catch (error){
                event.data.distributorDiscount = oldValue
                gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //Validate range for newValue
            if(newValue >= 0 && newValue <= 100){
                let groupId = event.data.id
                this.props.setIsLoading && this.props.setIsLoading(true)
                ccChangeGroupDistributorDiscountReactive(groupId, newValue, false).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    let requiresOverride = response.data.requiresOverride
                    if(requiresOverride){ //If requires override then reset the value to old value
                        this.props.setOverrideDistributorDiscountModalIsOpen && this.props.setOverrideDistributorDiscountModalIsOpen(true)
                        this.props.setOverrideDiscountParams && this.props.setOverrideDiscountParams(event)
                        this.props.setOverrideDiscountNewValue && this.props.setOverrideDiscountNewValue(newValue)
                        event.data.distributorDiscount = oldValue
                        gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                    else{
                        event.data.distributorDiscount = newValue
                        gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.distributorDiscount = oldValue
                    gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error("The discount must be greater than or equal to 0 and less than or equal to 100");
                event.data.distributorDiscount = oldValue
                gridApi.refreshCells({columns: ["distributorDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "resellerDiscount"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                //event.data.resellerDiscount = oldValue
                //gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: false, force: true, rowNodes: [event.node]})
                return;
            }
            //validate newValue is between 0-100
            try{
                let parseFloatValue = parseFloat(newValue).toFixed(2)
                if(isNaN(parseFloatValue) || parseFloatValue === null){
                    event.data.resellerDiscount = oldValue
                    gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseFloatValue
                }
            }
            catch (error){
                event.data.resellerDiscount = oldValue
                gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //Validate range for newValue
            if(newValue >= 0 && newValue <= 100){
                let groupId = event.data.id
                this.props.setIsLoading && this.props.setIsLoading(true)
                ccChangeGroupResellerDiscountReactive(groupId, newValue, false).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    let requiresOverride = response.data.requiresOverride
                    if(requiresOverride){ //If requires override then reset the value to old value
                        this.props.setOverrideResellerDiscountModalIsOpen && this.props.setOverrideResellerDiscountModalIsOpen(true)
                        this.props.setOverrideDiscountParams && this.props.setOverrideDiscountParams(event)
                        this.props.setOverrideDiscountNewValue && this.props.setOverrideDiscountNewValue(newValue)
                        event.data.resellerDiscount = oldValue
                        gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                    else{
                        event.data.resellerDiscount = newValue
                        gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.resellerDiscount = oldValue
                    gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error("The discount must be greater than or equal to 0 and less than or equal to 100");
                event.data.resellerDiscount = oldValue
                gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "referralDiscount"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                //event.data.referralDiscount = oldValue
                //gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: false, force: true, rowNodes: [event.node]})
                return;
            }
            //validate newValue is between 0-100
            try{
                let parseFloatValue = parseFloat(newValue).toFixed(2)
                if(isNaN(parseFloatValue) || parseFloatValue === null){
                    event.data.referralDiscount = oldValue
                    gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseFloatValue
                }
            }
            catch (error){
                event.data.referralDiscount = oldValue
                gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //Validate range for newValue
            if(newValue >= 0 && newValue <= 100){
                let groupId = event.data.id
                this.props.setIsLoading && this.props.setIsLoading(true)
                ccChangeGroupReferralDiscountReactive(groupId, newValue, false).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    let requiresOverride = response.data.requiresOverride
                    if(requiresOverride){ //If requires override then reset the value to old value
                        this.props.setOverrideReferralDiscountModalIsOpen && this.props.setOverrideReferralDiscountModalIsOpen(true)
                        this.props.setOverrideDiscountParams && this.props.setOverrideDiscountParams(event)
                        this.props.setOverrideDiscountNewValue && this.props.setOverrideDiscountNewValue(newValue)
                        event.data.referralDiscount = oldValue
                        gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                    else{
                        event.data.referralDiscount = newValue
                        gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    }
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.referralDiscount = oldValue
                    gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error("The discount must be greater than or equal to 0 and less than or equal to 100");
                event.data.referralDiscount = oldValue
                gridApi.refreshCells({columns: ["referralDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "partnerInvoiceDaysUntilDue"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                return;
            }
            if(newValue === null || newValue === undefined){
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //validate input
            try{
                let parseIntValue = parseInt(newValue)
                if(isNaN(parseIntValue) || parseIntValue === null){
                    NotificationManager.error("Invalid input for number of days, you must enter an integer");
                    event.data.partnerInvoiceDaysUntilDue = oldValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseIntValue
                }
            } catch (e) {
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //check max/min value of the new value
            if(newValue >= minDaysUntilInvoiceDue && newValue <= maxDaysUntilInvoiceDue){
                let groupId = event.data.id
                this.props.setIsLoading && this.props.setIsLoading(true)
                ccChangeGroupDaysUntilInvoiceDueReactive(groupId, newValue).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerInvoiceDaysUntilDue = newValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerInvoiceDaysUntilDue = oldValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error(`The days must be greater than or equal to ${minDaysUntilInvoiceDue} and less than or equal to ${maxDaysUntilInvoiceDue}`);
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "partnerMaxDaysForPOCTrialLicensesToExpire"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                return;
            }
            if(newValue === null || newValue === undefined){
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //validate input
            try{
                let parseIntValue = parseInt(newValue)
                if(isNaN(parseIntValue) || parseIntValue === null){
                    NotificationManager.error("Invalid input for number of days, you must enter an integer");
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseIntValue
                }
            } catch (e) {
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //check max/min value of the new value
            if(newValue >= minDaysForTrialLicenseExpiration && newValue <= maxDaysUntilTrialLicenseExpiration){
                let groupId = event.data.id
                this.props.setIsLoading && this.props.setIsLoading(true)
                ccChangeGroupMaxDaysUntilTrialLicensesExpireReactive(groupId, newValue).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = newValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error(`The days must be greater than or equal to ${minDaysForTrialLicenseExpiration} and less than or equal to ${maxDaysUntilTrialLicenseExpiration}`);
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "distributorContact"){
            //This allows to send null/empty for name to remove it, but endpoint checks to only allow this for partners that are not distributors
            if(!event.valueChanged){
                event.data.distributorContact = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }

            let groupId = event.data.id
            this.props.setIsLoading && this.props.setIsLoading(true)
            ccChangeDistributorContactReactive(groupId, event.newValue).then(response => {
                this.props.setIsLoading && this.props.setIsLoading(false)
                event.data.distributorContact = event.newValue
                gridApi.refreshCells({columns: ["distributorContact"], suppressFlash: true, force: true, rowNodes: [event.node]})
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                this.props.setIsLoading && this.props.setIsLoading(false)
                event.data.distributorContact = event.oldValue
                gridApi.refreshCells({columns: ["distributorContact"], suppressFlash: true, force: true, rowNodes: [event.node]})
            })
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on username asc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "zenGroupDisplayName", sort: "asc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/sse/ccGetPartnerGroupsReactive", "/sse/listenToPartnerGroupEventsReactive",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, null, this.updateTransactionsToApply,
            this.abortController)
    };

    detailCellRendererParams = (detailParams) => {
        let groupId = detailParams.node.data.id
        let detailGridColumnDefs = [
            {
                field: "username", headerName: `Username`, sortable: true, width: 600,
                filter: 'agSetColumnFilter',
                filterParams: {
                    suppressSorting: false,
                    buttons: ["reset", "apply", "cancel"],
                }
            }
        ]
        return {
            detailGridOptions: {
                getRowId: (params) => {
                    return params.data.username;
                },
                columnDefs: detailGridColumnDefs,
                defaultColDef: {
                    resizable: true,
                    floatingFilter: true,
                    cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                },
                suppressExcelExport: true,
                suppressCsvExport: true,
                enableCellTextSelection : true,
                getContextMenuItems: (params => {return ["resetColumns", "autoSizeAll"]})
            },
            getDetailRowData: async (detailRowDataParams) => {
                let detailRowData = []
                try{
                    detailRowData = await ccGetUsersInGroupReactive(groupId)
                } catch (e) {}
                detailRowDataParams.successCallback(detailRowData)
            },
        }

    }
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor, customDoubleCellEditor: CustomDoubleCellEditor, customGroupInvoiceDaysUntilDueCellEditor: CustomGroupInvoiceDaysUntilDueCellEditor,
                            customGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor: CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor}}
                        multiSortKey={"ctrl"}
                        columnDefs={this.props.columnDefs}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={'single'}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        detailRowHeight={400}
                        keepDetailRows={true} //keeps detail rows
                        masterDetail={true}
                        detailCellRendererParams={this.detailCellRendererParams}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
