import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {
    CustomDoubleCellEditor,
    CustomGroupInvoiceDaysUntilDueCellEditor,
    CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor
} from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useForm} from "react-hook-form";
import {Autocomplete, Button, FormControlLabel, IconButton, Switch, TextField, ThemeProvider} from "@mui/material";
import {autocompleteTheme, buttonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip
} from "../../components/muiComponents";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parentDistributorGroupNameValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import Modal from "react-modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import * as EmailValidator from "email-validator";
import {getPartnerGroupSettingsReactive} from "../api/groupsApi";
import {
    changeSubgroupCanCreatePOCReactive,
    changeSubgroupDaysUntilInvoiceDueReactive,
    changeSubgroupIsResellerReactive,
    changeSubgroupMaxDaysUntilTrialLicensesExpireReactive,
    changeSubgroupResellerDiscountReactive,
    createDistributorSubgroupReactive,
    getGroupsWithoutParentPartnerAssignedReactive, getSignedResellerAgreementReactive,
    promoteExistingGroupToSubgroupReactive,
    removeSubgroupFromParentDistributorReactive,
    subgroupTurnChannelPartnerSettingOffReactive,
    updateDistributorManagementGridColumnModeReactive,
    updateDistributorManagementGridColumnStateReactive, updateDistributorManagementGridFilterModelReactive,
    updateDistributorManagementGridUseColumnStateReactive,
    updateDistributorManagementGridUseFilterStateReactive, uploadSignedResellerAgreementReactive
} from "../api/distributorGroupManagementApi";
import {
    maxDaysUntilInvoiceDue,
    minDaysForTrialLicenseExpiration,
    minDaysUntilInvoiceDue
} from "./ccOnlyPartnerGroupManagement";
import {getItemFromStorageWithoutDecrypting} from "../../utils/storageHelper";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper, onFilterChangedHelper, onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText, mediumColumnModeText,
    minColumnModeText, standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {getBrowserIncidentCCDiffFileReactive} from "../api/browserDataIncidentsApi";
import {base64ToArrayBuffer} from "./incidents";
import {blockedIdentityAccessPage, hiddenIdentityAccessPage} from "./browserDataIncidents";
import {ccCreateStripeSubscriptionForDealReactive} from "../api/dealsApi";

//These percentages and days are verified with what the specific distributor group has set, these are just in general numbers
export const minDaysUntilInvoiceDueNonCC = 1
export const minDaysForTrialLicenseExpirationNonCC = 1
export const maxDaysUntilInvoiceDueNonCC = 180
export const maxDaysUntilTrialLicenseExpirationNonCC = 90

export const nonCCDistributorDefaultResellerPercentage = 30.00
export const nonCCDistributorDefaultDaysUntilInvoicesDue = 30
export const nonCCDistributorDefaultMaxTrialDaysForPOCLicenses = 14
let gridFilterStateSessionVariableName = "distributorManagementGridFilterState"
let gridColumnStateSessionVariableName = "distributorManagementGridColumnState"
let minColumnIds = ["parentGroupDisplayName", "zenGroupDisplayName", "channelPartner", "signedResellerAgreement"]
let medColumnIds = ["parentGroupDisplayName", "zenGroupDisplayName", "channelPartner", "isReseller", "canCreatePOC", "partnerInvoiceDaysUntilDue", "signedResellerAgreement"]

export default function DistributorGroupManagement() {
    const { register, handleSubmit, reset } = useForm();
    const { register: registerUploadAgreementModal, handleSubmit: handleUploadAgreementModalSubmit, reset: resetUploadAgreementModal } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession(gridFilterStateSessionVariableName));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [createNewSubgroupModalIsOpen, setCreateNewSubgroupModalIsOpen] = useState(false);
    const [associateExistingGroupAsSubgroupModalIsOpen, setAssociateExistingGroupAsSubgroupModalIsOpen] = useState(false);
    const [groupsToPromoteQueriedAlready, setGroupsToPromoteQueriedAlready] = useState(false);
    const [groupsToPromoteList, setGroupsToPromoteList] = useState([]);
    const [promoteGroupToSubgroupDropdownOptions, setPromoteGroupToSubgroupDropdownOptions] = useState([]);
    const [userChannelPartnerGroupSettingsList, setUserChannelPartnerGroupSettingsList] = useState([]);
    const [modalParentDistributorDropdownOptions, setModalParentDistributorDropdownOptions] = useState([]);
    const [modalParentDistributorGroupIdSelected, setModalParentDistributorGroupIdSelected] = useState(null);
    const [modalExistingGroupIdSelectedToBeSubgroup, setModalExistingGroupIdSelectedToBeSubgroup] = useState(null);
    const [modalIsPartner, setModalIsPartner] = useState(false);
    const [modalIsReseller, setModalIsReseller] = useState(false);
    const [modalCanCreatePOC, setModalCanCreatePOC] = useState(false);
    const [modalParentDistributorCanCreatePOCs, setModalParentDistributorCanCreatePOCs] = useState(false);
    const [modalMaxDiscountValue, setModalMaxDiscountValue] = useState(0);
    const [modalMaxDaysUntilInvoiceDue, setModalMaxDaysUntilInvoiceDue] = useState(0);
    const [modalMaxDaysPOCTrialLicensesCanExpire, setModalMaxDaysPOCTrialLicensesCanExpire] = useState(0);
    const [modalSubgroupAdminUsersList, setModalSubgroupAdminUsersList] = useState([]);
    const [uploadSignedAgreementModalIsOpen, setUploadSignedAgreementModalIsOpen] = useState(false);
    const [uploadAgreementRowData, setUploadAgreementRowData] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200ms to start for initial data pull, then turns to 5000ms
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "parentGroupDisplayName", initialWidth: 330, headerName: "Parent Distributor Group", filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            pinned: 'left',
            sortable: true,
            valueGetter: parentDistributorGroupNameValueGetter,
        },
        {
            field: "zenGroupDisplayName", initialWidth: 330, headerName: "Subgroup", filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <div className={"flex flex-row flex-nowrap items-center justify-start gap-x-1"}>
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-square-xmark"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to disassociate this subgroup from the parent distributor group"}
                                tooltipPlacement={"bottom-start"}
                                onClick={(event) => {
                                    removeSubgroupFromParentDistributorReactive(params.node.data.id).then(response => {
                                        NotificationManager.success("Successfully disassociated this subgroup")
                                    }).catch(error => {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    })
                                }}
                            />
                        </div>
                        {params.node.data.zenGroupDisplayName}
                    </div>
                )
            }
        },
        { field: "channelPartner", headerName: "Subgroup Can Register Deals", initialWidth: 300, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Subgroup Can Register Deals', 'Subgroup Cannot Register Deals'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.channelPartner){
                        return "Subgroup Can Register Deals";
                    }else{
                        return "Subgroup Cannot Register Deals";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.channelPartner){
                    return "Subgroup Can Register Deals";
                }else{
                    return "Subgroup Cannot Register Deals";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartner}
                                    name={`cellToggleChannelPartner${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    disabled={!params.node.data.channelPartner}
                                    onChange={e => {
                                        subgroupTurnChannelPartnerSettingOffReactive(params.node.data.id).then(response => {
                                            try{
                                                params.node.setDataValue("channelPartner", false)
                                                params.node.setDataValue("isReseller", false)
                                                params.node.setDataValue("canCreatePOC", false)
                                            }
                                            catch(error){}
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "isReseller", headerName: "Is a Reseller", initialWidth: 200, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Reseller', 'Not a Reseller'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.isReseller){
                        return "Reseller";
                    }else{
                        return "Not a Reseller";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.isReseller){
                    return "Reseller";
                }else{
                    return "Not a Reseller";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.isReseller}
                                    name={`cellToggleIsAReseller${params.node.data.id}`}
                                    className={"cursor-pointer"}
                                    onChange={e => {
                                        changeSubgroupIsResellerReactive(params.node.data.id, e.target.checked).then(response => {

                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "canCreatePOC", headerName: "Can Create POC", initialWidth: 225, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Can Create POC', 'Cannot Create POC'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.canCreatePOC){
                        return "Can Create POC";
                    }else{
                        return "Cannot Create POC";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.canCreatePOC){
                    return "Can Create POC";
                }else{
                    return "Cannot Create POC";
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.canCreatePOC}
                                name={`cellToggleCanCreatePOC${params.node.data.id}`}
                                className={"cursor-pointer"}
                                onChange={e => {
                                    changeSubgroupCanCreatePOCReactive(params.node.data.id, e.target.checked).then(response => {

                                    }).catch(error => {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    })
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            sortable: true
        },
        { field: "resellerDiscount", headerName: "Reseller Deal Discount %", sortable: true, initialWidth: 265, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            valueFormatter: function(params){
                if(params.value !== null && params.value !== undefined){
                    return `${params.value}%`
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "resellerDiscount"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the reseller discount"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {`${params.value}%`}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customDoubleCellEditor"
        },
        { field: "partnerInvoiceDaysUntilDue", headerName: "Days Until Deal Invoices are Due", sortable: true, initialWidth: 315, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "partnerInvoiceDaysUntilDue"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the days until partner deal invoices are due for this group"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customGroupInvoiceDaysUntilDueCellEditor"
        },
        { field: "partnerMaxDaysForPOCTrialLicensesToExpire", headerName: "Max Days Until POC Trial Licenses Can Expire", sortable: true, initialWidth: 400, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "partnerMaxDaysForPOCTrialLicensesToExpire"
                                    })
                                }
                            }}
                            tooltipTitle={"Click to edit the max days a partner can set POC trial licenses to expire when creating a POC group"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
            editable: true,
            cellEditor: "customGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor"
        },
        { field: "signedResellerAgreement", headerName: "Subgroup Reseller Agreement", initialWidth: 300, filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Signed Agreement", "No Signed Agreement"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.signedResellerAgreementId !== null && params.node.data.signedResellerAgreementId !== undefined){
                        return "Signed Agreement";
                    }else{
                        return "No Signed Agreement";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.signedResellerAgreementId !== null && params.node.data.signedResellerAgreementId !== undefined){
                    return "Signed Agreement";
                }else{
                    return "No Signed Agreement";
                }
            },
            cellRenderer:
                function (params) {
                    let iconDiv = ""
                    if(params.node.data.signedResellerAgreementId !== null && params.node.data.signedResellerAgreementId !== undefined){
                        iconDiv =
                            <MuiIconButtonWithTooltip
                                icon={ <FontAwesomeIcon className="object-contain" icon="fa-duotone fa-download" size="xs"/>}
                                tooltipTitle={"Click to download the signed reseller agreement with this subgroup"}
                                tooltipPlacement={"bottom-start"}
                                onClick={() => {
                                    if(params.node.data.id !== null && params.node.data.id !== undefined){
                                        setIsLoading(true)
                                        getSignedResellerAgreementReactive(params.node.data.id).then(response => {
                                            setIsLoading(false)
                                            try{
                                                let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);

                                                let today = new Date();
                                                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                let time = today.getHours() + "-" + today.getMinutes();
                                                let dateTime = date + 'T' + time;

                                                link.download=`SignedResellerAgreement-${dateTime}.pdf`;
                                                link.click();
                                            } catch(error){
                                                NotificationManager.error("Unexpected error downloading signed agreement");
                                            }
                                        }).catch(error => {
                                            setIsLoading(false)
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                        })
                                    }

                                }}
                            />
                    }
                    else{
                        iconDiv =
                            <MuiIconButtonWithTooltip
                                icon={ <FontAwesomeIcon className="object-contain" icon="fa-duotone fa-upload" size="xs"/>}
                                tooltipTitle={"Click to upload the signed reseller agreement with this subgroup"}
                                tooltipPlacement={"bottom-start"}
                                onClick={() => {
                                    resetUploadSignedAgreementModal()
                                    setUploadAgreementRowData(params.node.data)
                                    setUploadSignedAgreementModalIsOpen(true)
                                }}
                            />
                    }
                    return (
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            {iconDiv}
                            {params.value}
                        </div>
                    )
                },
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            headerClass:"border-0 border-b-0",
            cellClass:"outline:none",
            enableCellChangeFlash:true,
            floatingFilter:true,
            autoHeight:false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )

    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(375)
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            getPartnerGroupSettingsReactive().then(response => {
                if(response === null || response === undefined){
                    setUserChannelPartnerGroupSettingsList([])
                }
                else{
                    response.sort((object1, object2) => (object1.friendlyName?.toLowerCase() > object2.friendlyName?.toLowerCase()) ? 1 : -1)
                    setUserChannelPartnerGroupSettingsList(response)
                    //set the modalParentDistributorDropdownOptions for distributor groups only
                    let isDistributorGroups = []
                    response.forEach(group => {
                        if(group.isDistributor){
                            let objToPush = {}
                            objToPush.value = group.id
                            objToPush.label = group.friendlyName || group.id
                            isDistributorGroups.push(objToPush)
                        }
                    })
                    setModalParentDistributorDropdownOptions(isDistributorGroups)
                }

            }).catch(error => {
                setUserChannelPartnerGroupSettingsList([])
            })
        })()
        return () => controller?.abort();
    }, []);

    const submitCreateNewSubgroup = (data) => {
        if(!data){
            NotificationManager.error("Unexpected error making this request");
            return;
        }
        if(modalParentDistributorGroupIdSelected === null || modalParentDistributorGroupIdSelected === undefined){
            NotificationManager.info("You must select the parent distributor for this subgroup")
            return;
        }
        if(data.newSubgroupName === null || data.newSubgroupName === undefined || data.newSubgroupName.trim().length < 1){
            NotificationManager.info("You must enter a name for this group")
            return;
        }
        if(!modalSubgroupAdminUsersList || modalSubgroupAdminUsersList.length === 0){
            NotificationManager.info("You must enter at least 1 admin user");
            return
        }
        if(modalSubgroupAdminUsersList.length > 5){
            NotificationManager.info("You can enter up to 5 admin users");
            return
        }
        let userName = getItemFromStorageWithoutDecrypting("username")
        let foundInvalidEmail = false
        let userEnteredThemself = false
        modalSubgroupAdminUsersList.forEach(username => {
            if(userName !== null && userName !== undefined && username.trim().toLowerCase() === userName.trim().toLowerCase()){
                userEnteredThemself = true
            }
            if(!EmailValidator.validate(username.trim().toLowerCase())){
                foundInvalidEmail = true
            }
        })

        if(userEnteredThemself){
            NotificationManager.info("You cannot set yourself to be an admin of the subgroup")
            return;
        }
        if(foundInvalidEmail){
            NotificationManager.info("You have entered an invalid user email address, please fix before continuing");
            return;
        }

        //Only applying certain values if modalIsPartner is true. Discounts will be ignored in endpoint if modalIsPartner is false
        let isResellerValueToSend = false
        let canSetupPOCValueToSend = false

        if(modalIsPartner){
            if(!modalIsReseller){
                NotificationManager.error("You must select if the subgroup is a reseller")
                return;
            }
            isResellerValueToSend = modalIsReseller
            if(modalParentDistributorCanCreatePOCs && modalCanCreatePOC){
                //If the parent distributor can create POCs and createPOC is toggled to on, then set canSetupPOCValueToSend to true
                canSetupPOCValueToSend = true
            }
            //If subgroup is going to be a partner, verify max's with the selected parent partner group settings
            let selectedGroup = userChannelPartnerGroupSettingsList.find((group) => group.id === modalParentDistributorGroupIdSelected);
            if(selectedGroup !== null && selectedGroup !== undefined){
                let distributorMaxDaysUntilInvoicesDue = selectedGroup.partnerInvoiceDaysUntilDue
                let distributorMaxDaysForPOCTrialLicensesToExpire = selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire
                let distributorMaxDiscountPercent = selectedGroup.distributorDiscount

                if(modalIsReseller && distributorMaxDiscountPercent){
                    //verify reseller discount
                    if(data.resellerDiscount > distributorMaxDiscountPercent){
                        NotificationManager.error(`The max reseller discount you can set is ${distributorMaxDiscountPercent}`)
                        return;
                    }
                }
                if(distributorMaxDaysUntilInvoicesDue){
                    if(data.daysUntilDealInvoicesDue > distributorMaxDaysUntilInvoicesDue){
                        NotificationManager.error(`The max days you can set an invoice to be due is ${distributorMaxDaysUntilInvoicesDue}`)
                        return;
                    }
                }
                if(canSetupPOCValueToSend && distributorMaxDaysForPOCTrialLicensesToExpire){
                    if(data.partnerMaxDaysForPOCTrialLicensesToExpire > distributorMaxDaysForPOCTrialLicensesToExpire){
                        NotificationManager.error(`The max days POC trial licenses can expire are ${distributorMaxDaysForPOCTrialLicensesToExpire}`)
                        return;
                    }
                }
            }
            //else just let the endpoint verify
        }

        setIsLoading(true)
        createDistributorSubgroupReactive(modalParentDistributorGroupIdSelected, data.newSubgroupName.trim(), modalSubgroupAdminUsersList, isResellerValueToSend,
            false, canSetupPOCValueToSend, data.resellerDiscount, null, data.daysUntilDealInvoicesDue, data.partnerMaxDaysForPOCTrialLicensesToExpire).then(response => {
            setIsLoading(false)
            NotificationManager.success("Successfully created new subgroup")
            resetModalFields()
        }).catch(error => {
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error making this request");
            }
        })


    };

    const submitAssociateExistingGroupWithDistributor = (data) => {
        if(!data){
            NotificationManager.error("Unexpected error making this request");
            return;
        }
        if(modalParentDistributorGroupIdSelected === null || modalParentDistributorGroupIdSelected === undefined){
            NotificationManager.info("You must select the parent distributor for this subgroup")
            return;
        }
        if(modalExistingGroupIdSelectedToBeSubgroup === null || modalExistingGroupIdSelectedToBeSubgroup === undefined){
            NotificationManager.info("You must select the group to promote to subgroup")
            return;
        }
        //Make sure parent and subgroup are not the same, shouldn't happen though
        if(modalParentDistributorGroupIdSelected === modalExistingGroupIdSelectedToBeSubgroup){
            NotificationManager.info("The parent distributor group and the subgroup cannot be the same")
            return;
        }

        //Only applying certain values if modalIsPartner is true. Discounts will be ignored in endpoint if modalIsPartner is false
        let isResellerValueToSend = false
        let canSetupPOCValueToSend = false

        if(modalIsPartner){
            if(!modalIsReseller){
                NotificationManager.error("You must select if the subgroup is a reseller")
                return;
            }
            isResellerValueToSend = modalIsReseller
            if(modalParentDistributorCanCreatePOCs && modalCanCreatePOC){
                //If the parent distributor can create POCs and createPOC is toggled to on, then set canSetupPOCValueToSend to true
                canSetupPOCValueToSend = true
            }
            //If subgroup is going to be a partner, verify max's with the selected parent partner group settings
            let selectedGroup = userChannelPartnerGroupSettingsList.find((group) => group.id === modalParentDistributorGroupIdSelected);
            if(selectedGroup !== null && selectedGroup !== undefined){
                let distributorMaxDaysUntilInvoicesDue = selectedGroup.partnerInvoiceDaysUntilDue
                let distributorMaxDaysForPOCTrialLicensesToExpire = selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire
                let distributorMaxDiscountPercent = selectedGroup.distributorDiscount

                if(modalIsReseller && distributorMaxDiscountPercent){
                    //verify reseller discount
                    if(data.resellerDiscount > distributorMaxDiscountPercent){
                        NotificationManager.error(`The max reseller discount you can set is ${distributorMaxDiscountPercent}`)
                        return;
                    }
                }
                if(distributorMaxDaysUntilInvoicesDue){
                    if(data.daysUntilDealInvoicesDue > distributorMaxDaysUntilInvoicesDue){
                        NotificationManager.error(`The max days you can set an invoice to be due is ${distributorMaxDaysUntilInvoicesDue}`)
                        return;
                    }
                }
                if(canSetupPOCValueToSend && distributorMaxDaysForPOCTrialLicensesToExpire){
                    if(data.partnerMaxDaysForPOCTrialLicensesToExpire > distributorMaxDaysForPOCTrialLicensesToExpire){
                        NotificationManager.error(`The max days POC trial licenses can expire are ${distributorMaxDaysForPOCTrialLicensesToExpire}`)
                        return;
                    }
                }
            }
            //else just let the endpoint verify
        }
        setIsLoading(true)
        promoteExistingGroupToSubgroupReactive(modalParentDistributorGroupIdSelected, modalExistingGroupIdSelectedToBeSubgroup, isResellerValueToSend,
            false, canSetupPOCValueToSend, data.resellerDiscount, null, data.daysUntilDealInvoicesDue, data.partnerMaxDaysForPOCTrialLicensesToExpire).then(response => {
            setIsLoading(false)
            NotificationManager.success("Successfully promoted this group to a subgroup")
            resetModalFields()
        }).catch(error => {
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error making this request");
            }
        })


    };

    function resetModalFields(){
        setAssociateExistingGroupAsSubgroupModalIsOpen(false)
        setCreateNewSubgroupModalIsOpen(false)
        setModalIsPartner(false)
        setModalIsReseller(false)
        setModalCanCreatePOC(false)
        setModalParentDistributorCanCreatePOCs(false)
        setModalParentDistributorGroupIdSelected(null)
        setModalExistingGroupIdSelectedToBeSubgroup(null)
        setModalMaxDiscountValue(0)
        setModalMaxDaysUntilInvoiceDue(0)
        setModalMaxDaysPOCTrialLicensesCanExpire(0)
        setModalSubgroupAdminUsersList([])
        reset({
            resellerDiscount: null,
            daysUntilDealInvoicesDue: null,
            partnerMaxDaysForPOCTrialLicensesToExpire: null,
            newSubgroupName: ""
        })
    }

    function resetUploadSignedAgreementModal(){
        setUploadSignedAgreementModalIsOpen(false)
        setUploadAgreementRowData(null)
        resetUploadAgreementModal({
            signedAgreementPDF: null
        })
    }

    const uploadSignedAgreementModal = (data) => {
        if(data && data.signedAgreementPDF){
            if(uploadAgreementRowData && uploadAgreementRowData.id && uploadAgreementRowData.parentPartnerGroupId){
                //check file type now
                let signedAgreementPDF = data.signedAgreementPDF[0]
                if(!signedAgreementPDF){
                    NotificationManager.error("Unexpected error uploading this file and completing the request");
                    return
                }
                if(signedAgreementPDF.type !== "application/pdf"){
                    NotificationManager.error("The signed agreement must be a PDF");
                    return
                }
                setIsLoading(true)
                uploadSignedResellerAgreementReactive(uploadAgreementRowData.parentPartnerGroupId, uploadAgreementRowData.id, signedAgreementPDF).then(response => {
                    setIsLoading(false)
                    NotificationManager.success("Successfully uploaded the signed agreement");
                    resetUploadSignedAgreementModal()
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error completing this request");
                    }
                    setIsLoading(false)
                })
            }
            else{
                NotificationManager.error("Unexpected error submitting request");
            }
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Distributor Group Management</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Create Distributor Subgroup"
                   isOpen={createNewSubgroupModalIsOpen}
                   onRequestClose={() => {
                       resetModalFields()
                   }}
                   shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitCreateNewSubgroup)}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Create Distributor Subgroup</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetModalFields()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="flex flex-col">
                            <label>Select the Parent Distributor for the New Subgroup</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={modalParentDistributorDropdownOptions}
                                value={modalParentDistributorGroupIdSelected}
                                onChange={( event, value ) => {
                                    handleParentGroupChangedForNewSubgroupAndPromoteAsSubgroup(value)
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <label>Subgroup Name</label>
                            <input
                                type="text"
                                name="newSubgroupName"
                                {...register("newSubgroupName")} required
                                className="focus:outline-none h-10 p-2 w-full rounded-lg border border-black border-opacity-25 border-solid"
                                placeholder={'Name'}
                            />
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <label>Subgroup Admins - You may enter multiple users, but cannot add yourself</label>
                            <ThemeProvider theme={autocompleteTheme}>
                                <Autocomplete
                                    className="" disablePortal={true} freeSolo openOnFocus={true} size={"small"}
                                    options={[]} //only allow for them to input the users since they will likely not be existing yet
                                    multiple //allow for multiple users to be entered
                                    renderInput={(params) => <TextField {...params} label={"Click the Enter key after each user is typed to add the user"} />}
                                    onChange={(event, valueList) => {
                                        setModalSubgroupAdminUsersList(valueList)
                                    }}
                                    onKeyDown={(event) => {
                                        if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsPartner}
                                        name="subgroupIsPartnerToggle"
                                        onChange={e => setModalIsPartner(e.target.checked)}
                                    />
                                } label={modalIsPartner ? "Subgroup Can Register Deals" : "Subgroup Cannot Register Deals"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReseller}
                                        disabled={!modalIsPartner}
                                        name="newPartnerGroupModalIsResellerToggle"
                                        onChange={e => setModalIsReseller(e.target.checked)}
                                    />
                                } label={modalIsReseller ? "Subgroup Is a Reseller" : "Subgroup Is Not a Reseller"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalCanCreatePOC}
                                        disabled={!modalIsPartner || !modalParentDistributorCanCreatePOCs}
                                        name="newPartnerGroupModalCanCreatePOCToggle"
                                        onChange={e => setModalCanCreatePOC(e.target.checked)}
                                    />
                                } label={modalCanCreatePOC ? "Subgroup Can Setup Their Own POCs" : "Subgroup Cannot Setup Their Own POCs"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Reseller Deal Discount Percentage {(modalMaxDiscountValue && modalMaxDiscountValue > 0) ? `(Max: ${modalMaxDiscountValue}%)` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01} disabled={!modalIsPartner || !modalIsReseller} required={modalIsPartner && modalIsReseller}
                                type="number" name="resellerDiscount"
                                {...register("resellerDiscount")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Days Until Deal Invoices Are Due {(modalMaxDaysUntilInvoiceDue && modalMaxDaysUntilInvoiceDue > 0) ? `(Max: ${modalMaxDaysUntilInvoiceDue})` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysUntilInvoiceDueNonCC} step={1} disabled={!modalIsPartner} required={modalIsPartner}
                                type="number" name="daysUntilDealInvoicesDue"
                                {...register("daysUntilDealInvoicesDue")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Max Days POC Trial Licenses Can be Set to Expire {(modalMaxDaysPOCTrialLicensesCanExpire && modalMaxDaysPOCTrialLicensesCanExpire > 0) ? `(Max: ${modalMaxDaysPOCTrialLicensesCanExpire})` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysForTrialLicenseExpirationNonCC} step={1} disabled={!modalIsPartner || !modalCanCreatePOC} required={modalIsPartner && modalCanCreatePOC}
                                type="number" name="partnerMaxDaysForPOCTrialLicensesToExpire"
                                {...register("partnerMaxDaysForPOCTrialLicensesToExpire")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}>
                                    Create New Subgroup
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal contentLabel="Promote Existing Group as a Distributor Subgroup"
                   isOpen={associateExistingGroupAsSubgroupModalIsOpen}
                   onRequestClose={() => {
                       resetModalFields()
                   }}
                   shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitAssociateExistingGroupWithDistributor)}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Promote Existing Group as a Distributor Subgroup</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetModalFields()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="flex flex-col">
                            <label>Select the Parent Distributor for the Subgroup</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={modalParentDistributorDropdownOptions}
                                value={modalParentDistributorGroupIdSelected}
                                onChange={( event, value ) => {
                                    handleParentGroupChangedForNewSubgroupAndPromoteAsSubgroup(value)
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label>Select the Existing Group to Promote to Subgroup</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={promoteGroupToSubgroupDropdownOptions}
                                value={modalExistingGroupIdSelectedToBeSubgroup}
                                onChange={( event, value ) => {
                                    setModalExistingGroupIdSelectedToBeSubgroup(value?.value)
                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsPartner}
                                        name="subgroupIsPartnerToggle"
                                        onChange={e => setModalIsPartner(e.target.checked)}
                                    />
                                } label={modalIsPartner ? "Subgroup Can Register Deals" : "Subgroup Cannot Register Deals"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalIsReseller}
                                        disabled={!modalIsPartner}
                                        name="newPartnerGroupModalIsResellerToggle"
                                        onChange={e => setModalIsReseller(e.target.checked)}
                                    />
                                } label={modalIsReseller ? "Subgroup Is a Reseller" : "Subgroup Is Not a Reseller"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalCanCreatePOC}
                                        disabled={!modalIsPartner || !modalParentDistributorCanCreatePOCs}
                                        name="newPartnerGroupModalCanCreatePOCToggle"
                                        onChange={e => setModalCanCreatePOC(e.target.checked)}
                                    />
                                } label={modalCanCreatePOC ? "Subgroup Can Setup Their Own POCs" : "Subgroup Cannot Setup Their Own POCs"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Reseller Deal Discount Percentage {(modalMaxDiscountValue && modalMaxDiscountValue > 0) ? `(Max: ${modalMaxDiscountValue}%)` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                min={0} max={100} step={0.01} disabled={!modalIsPartner || !modalIsReseller} required={modalIsPartner && modalIsReseller}
                                type="number" name="resellerDiscount"
                                {...register("resellerDiscount")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Days Until Deal Invoices Are Due {(modalMaxDaysUntilInvoiceDue && modalMaxDaysUntilInvoiceDue > 0) ? `(Max: ${modalMaxDaysUntilInvoiceDue})` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysUntilInvoiceDueNonCC} step={1} disabled={!modalIsPartner} required={modalIsPartner}
                                type="number" name="daysUntilDealInvoicesDue"
                                {...register("daysUntilDealInvoicesDue")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`w-full`}>
                            <label>Subgroup Max Days POC Trial Licenses Can be Set to Expire {(modalMaxDaysPOCTrialLicensesCanExpire && modalMaxDaysPOCTrialLicensesCanExpire > 0) ? `(Max: ${modalMaxDaysPOCTrialLicensesCanExpire})` : ""}</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysForTrialLicenseExpirationNonCC} step={1} disabled={!modalIsPartner || !modalCanCreatePOC} required={modalIsPartner && modalCanCreatePOC}
                                type="number" name="partnerMaxDaysForPOCTrialLicensesToExpire"
                                {...register("partnerMaxDaysForPOCTrialLicensesToExpire")}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}>
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal contentLabel="Upload Signed Reseller Agreement"
                   isOpen={uploadSignedAgreementModalIsOpen}
                   onRequestClose={() => {
                       resetUploadSignedAgreementModal()
                   }}
                   shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-xl max-w-xl min-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleUploadAgreementModalSubmit(uploadSignedAgreementModal)}>
                    <div className="flex flex-1 flex-col gap-y-5 ml-1">
                        <div className="flex flex-row justify-between gap-x-2">
                            <h1 className="font-bold text-3xl">Upload Signed Reseller Agreement</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetUploadSignedAgreementModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        <div className="">
                            <label>Parent Distributor
                                Group: </label>{uploadAgreementRowData && uploadAgreementRowData.parentGroupDisplayName}
                        </div>
                        <div className="">
                            <label>Subgroup: </label>{uploadAgreementRowData && uploadAgreementRowData.zenGroupDisplayName}
                        </div>
                        <hr className="h-0.5"/>
                        <div className="">
                            {`Upload the signed reseller agreement between the distributor and subgroup in PDF format`}
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <label>Signed Agreement:</label>
                            <input className={'ml-2 w-min'} type="file" name="signedAgreementPDF" multiple={false}
                                   {...registerUploadAgreementModal("signedAgreementPDF")}
                                   required={true}
                                   accept="application/pdf, .pdf"
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                            <ThemeProvider theme={roundButtonTheme}>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        type={"submit"}
                                        style={{minWidth: "200px"}}>
                                    Upload Signed Agreement
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Distributor Group Management")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-arrow-up"} size="sm" color={`black`} />
                                        </IconButton>
                                    }
                                    tooltipTitle={"Promote Existing Group as a Distributor Subgroup"} tooltipPlacement={"top"}
                                    onClick={() => {
                                        resetModalFields() //reset before opening modal
                                        setAssociateExistingGroupAsSubgroupModalIsOpen(true)
                                        //check if we have already queried the autocomplete options yet, this is in place so we don't query this list unless user wants to promote a group to a subgroup
                                        if(!groupsToPromoteQueriedAlready) {
                                            setGroupsToPromoteQueriedAlready(true)
                                            getGroupsWithoutParentPartnerAssignedReactive().then(response => {
                                                if(response !== null && response !== undefined){
                                                    setGroupsToPromoteList(response)
                                                    let dropdownOptions = []
                                                    response.forEach(group => {
                                                        let objToPush = {}
                                                        objToPush.value = group.zenGroupId
                                                        objToPush.label = group.name || group.zenGroupId
                                                        dropdownOptions.push(objToPush)
                                                    })
                                                    setPromoteGroupToSubgroupDropdownOptions(dropdownOptions)
                                                }
                                                else{
                                                    setGroupsToPromoteList([])
                                                }
                                            }).catch(function (error) {
                                                setGroupsToPromoteList([])
                                            })
                                        }
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Create New Distributor Subgroup"} tooltipPlacement={"top"}
                                    onClick={() => {
                                        resetModalFields() //reset before opening modal
                                        setCreateNewSubgroupModalIsOpen(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                disableMediumOption={false} disableMinOption={false} updateGridColumnModeFunction={updateDistributorManagementGridColumnModeReactive}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds}
                            />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                            columnMode={columnMode}
                            setColumnMode={setColumnMode}
                        />
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function handleParentGroupChangedForNewSubgroupAndPromoteAsSubgroup(value){
        let sameGroupSelected = false
        if(value && value.value && modalParentDistributorGroupIdSelected === value.value){
            sameGroupSelected = true
        }
        setModalParentDistributorGroupIdSelected(value?.value)
        //Check if this parent distributor can create POC
        if(value && value.value){
            if(sameGroupSelected) {
                //Don't reset values because they just clicked the same group they had before
                return
            }
            let selectedGroup = userChannelPartnerGroupSettingsList.find((group) => group.id === value.value);
            if(selectedGroup){ //check max values for selected parent group
                let defaultResellerDiscount = nonCCDistributorDefaultResellerPercentage
                let defaultInvoiceDueDays = nonCCDistributorDefaultDaysUntilInvoicesDue
                let defaultMaxDaysPOCTrialLicensesCanExpire = nonCCDistributorDefaultMaxTrialDaysForPOCLicenses
                //canCreatePOC
                if(selectedGroup.canCreatePOC){
                    setModalParentDistributorCanCreatePOCs(true)
                }
                else {
                    setModalParentDistributorCanCreatePOCs(false)
                    setModalCanCreatePOC(false)
                }
                //distributorDiscount
                if(selectedGroup.distributorDiscount){
                    setModalMaxDiscountValue(selectedGroup.distributorDiscount)
                    if(defaultResellerDiscount > selectedGroup.distributorDiscount) {
                        defaultResellerDiscount = selectedGroup.distributorDiscount
                    }
                }
                else {
                    setModalMaxDiscountValue(0)
                }
                //partnerInvoiceDaysUntilDue
                if(selectedGroup.partnerInvoiceDaysUntilDue) {
                    setModalMaxDaysUntilInvoiceDue(selectedGroup.partnerInvoiceDaysUntilDue)
                    if(defaultInvoiceDueDays > selectedGroup.partnerInvoiceDaysUntilDue) {
                        defaultInvoiceDueDays = selectedGroup.partnerInvoiceDaysUntilDue
                    }
                }
                else{
                    setModalMaxDaysUntilInvoiceDue(0)
                }
                //partnerMaxDaysForPOCTrialLicensesToExpire
                if(selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire) {
                    setModalMaxDaysPOCTrialLicensesCanExpire(selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire)
                    if(defaultMaxDaysPOCTrialLicensesCanExpire > selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire) {
                        defaultMaxDaysPOCTrialLicensesCanExpire = selectedGroup.partnerMaxDaysForPOCTrialLicensesToExpire
                    }
                }
                else {
                    setModalMaxDaysPOCTrialLicensesCanExpire(0)
                }
                reset({
                    resellerDiscount: defaultResellerDiscount,
                    daysUntilDealInvoicesDue: defaultInvoiceDueDays,
                    partnerMaxDaysForPOCTrialLicensesToExpire: defaultMaxDaysPOCTrialLicensesCanExpire
                })
            }
            else{
                //else did not find group somehow, should not happen
                setModalParentDistributorCanCreatePOCs(false)
                setModalCanCreatePOC(false)
                setModalMaxDiscountValue(0)
                setModalMaxDaysUntilInvoiceDue(0)
                setModalMaxDaysPOCTrialLicensesCanExpire(0)
                //Set default values for text inputs
                reset({
                    resellerDiscount: nonCCDistributorDefaultResellerPercentage,
                    daysUntilDealInvoicesDue: nonCCDistributorDefaultDaysUntilInvoicesDue,
                    partnerMaxDaysForPOCTrialLicensesToExpire: nonCCDistributorDefaultMaxTrialDaysForPOCLicenses
                })
            }

        }
        else{
            //the selected group was cleared
            setModalParentDistributorCanCreatePOCs(false)
            setModalCanCreatePOC(false)
            setModalMaxDiscountValue(0)
            setModalMaxDaysUntilInvoiceDue(0)
            setModalMaxDaysPOCTrialLicensesCanExpire(0)
            //Set default values for text inputs
            reset({
                resellerDiscount: null,
                daysUntilDealInvoicesDue: null,
                partnerMaxDaysForPOCTrialLicensesToExpire: null
            })
        }

    }
    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "distributorGroupManagementGridExport")
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, gridFilterStateSessionVariableName, updateDistributorManagementGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateDistributorManagementGridUseColumnStateReactive);
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "resellerDiscount"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                //event.data.resellerDiscount = oldValue
                //gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: false, force: true, rowNodes: [event.node]})
                return;
            }
            //validate newValue is between 0-100
            try{
                let parseFloatValue = parseFloat(newValue).toFixed(2)
                if(isNaN(parseFloatValue) || parseFloatValue === null){
                    event.data.resellerDiscount = oldValue
                    gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseFloatValue
                }
            }
            catch (error){
                event.data.resellerDiscount = oldValue
                gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //Validate range for newValue
            if(newValue >= 0 && newValue <= 100){
                let groupId = event.data.id
                //this.props.setIsLoading && this.props.setIsLoading(true)
                changeSubgroupResellerDiscountReactive(groupId, newValue).then(response => {
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.resellerDiscount = newValue
                    gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.resellerDiscount = oldValue
                    gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error("The discount must be greater than or equal to 0 and less than or equal to 100");
                event.data.resellerDiscount = oldValue
                gridApi.refreshCells({columns: ["resellerDiscount"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "partnerInvoiceDaysUntilDue"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                return;
            }
            if(newValue === null || newValue === undefined){
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //validate input
            try{
                let parseIntValue = parseInt(newValue)
                if(isNaN(parseIntValue) || parseIntValue === null){
                    NotificationManager.error("Invalid input for number of days, you must enter an integer");
                    event.data.partnerInvoiceDaysUntilDue = oldValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseIntValue
                }
            } catch (e) {
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //check max/min value of the new value
            if(newValue >= minDaysUntilInvoiceDue){ //max is verified for parent group inside the endpoint
                let groupId = event.data.id
                //this.props.setIsLoading && this.props.setIsLoading(true)
                changeSubgroupDaysUntilInvoiceDueReactive(groupId, newValue).then(response => {
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerInvoiceDaysUntilDue = newValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerInvoiceDaysUntilDue = oldValue
                    gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error(`The days must be greater than or equal to ${minDaysUntilInvoiceDue}`);
                event.data.partnerInvoiceDaysUntilDue = oldValue
                gridApi.refreshCells({columns: ["partnerInvoiceDaysUntilDue"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
        else if(event.column.colId === "partnerMaxDaysForPOCTrialLicensesToExpire"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue === oldValue){
                return;
            }
            if(newValue === null || newValue === undefined){
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //validate input
            try{
                let parseIntValue = parseInt(newValue)
                if(isNaN(parseIntValue) || parseIntValue === null){
                    NotificationManager.error("Invalid input for number of days, you must enter an integer");
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                    return;
                }
                else{
                    newValue = parseIntValue
                }
            } catch (e) {
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            //check max/min value of the new value
            if(newValue >= minDaysForTrialLicenseExpiration){ //max is verified for parent group inside the endpoint
                let groupId = event.data.id
                //this.props.setIsLoading && this.props.setIsLoading(true)
                changeSubgroupMaxDaysUntilTrialLicensesExpireReactive(groupId, newValue).then(response => {
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = newValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                    //this.props.setIsLoading && this.props.setIsLoading(false)
                    event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                    gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                })
            }
            else{
                NotificationManager.error(`The days must be greater than or equal to ${minDaysForTrialLicenseExpiration}`);
                event.data.partnerMaxDaysForPOCTrialLicensesToExpire = oldValue
                gridApi.refreshCells({columns: ["partnerMaxDaysForPOCTrialLicensesToExpire"], suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
        }
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateDistributorManagementGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateDistributorManagementGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateDistributorManagementGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateDistributorManagementGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    applyFilterStateAfterSSE(gridReadyParams){
        /*
            Wait to apply saved grid filters until initial grid is done loading with data because asyncTransactionWaitMillis is set to 200ms initially so every 200ms rows will be added to the grid.
            We need to wait until the rows are added so that the distributor and subgroup filters will have all the values because we are leaving it up to ag grid
            to populate the set filters for these columns.
        */
        let msToSleep = 300
        const timer = setTimeout(() => {
            onGridReadyHelper(gridReadyParams, gridFilterStateSessionVariableName);
        }, msToSleep)
        return () => clearTimeout(timer)

    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Only max/custom are allowed for this grid
        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateDistributorManagementGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateDistributorManagementGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        await loadDataWithSSEAndStartChangeStreamListener("/sse/getDistributorPartnerResellerGroupsReactive", "/sse/listenToDistributorSubgroupEventsReactive",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController, null, this.applyFilterStateAfterSSE)
    };

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor, customDoubleCellEditor: CustomDoubleCellEditor, customGroupInvoiceDaysUntilDueCellEditor: CustomGroupInvoiceDaysUntilDueCellEditor,
                            customGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor: CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor}}
                        multiSortKey={"ctrl"}
                        columnDefs={this.props.columnDefs}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        getContextMenuItems={this.getContextMenuItems}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, gridFilterStateSessionVariableName, updateDistributorManagementGridFilterModelReactive);
                        }}
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
