import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    IconButton,
    TextField,
    ThemeProvider,
    Tooltip
} from "@mui/material";
import {autocompleteTheme, buttonTheme} from "../utils/muiStyling";
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import {CreateGroupModal} from "./createGroupModal";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import {convertDateToDateWithSessionTimeZone} from "../utils/gridDateFormatter";
import {monthlyBillingCycleText} from "../pages/private/deals";

export const MuiStandardButton = ({buttonText, variant, theme=buttonTheme, color, onClick, startIcon}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Button
                variant={"contained"}
                color={"primary"}
                disableFocusRipple={true}
                onClick={onClick}
                startIcon={null}
            >
                {buttonText}
            </Button>
        </ThemeProvider>
    );
};

export const MuiStandardButtonWithEndIconAndTooltip = ({buttonText, tooltipTitle, tooltipPlacement, theme=buttonTheme, color, onClick, endIcon, sx={}}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} enterDelay={750} arrow>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={sx}
                    disableFocusRipple={true}
                    onClick={onClick}
                    endIcon={endIcon}
                >
                    {buttonText}
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
};

export const MuiStandardButtonWithStartIconAndTooltip = ({buttonText, tooltipTitle, tooltipPlacement, theme=buttonTheme, color, onClick, startIcon, sx={}}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} enterDelay={750} arrow>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={sx}
                    disableFocusRipple={true}
                    onClick={onClick}
                    startIcon={startIcon}
                >
                    {buttonText}
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
};

export const MuiCloseIconButton = ({onClick, sx = {color: "black"}}) => {
    return (
        <IconButton
            sx={sx}
            className={"self-center"}
            onClick={() => {
                onClick && onClick()
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}

export const MuiDealStatusLogoIconWithMenu = ({tooltipTitle, tooltipPlacement="bottom-start", followCursor = false, hideRenewOption=false, params,
                                                   hideApproveOption=false, hideRejectOption=false, hideSubscriptionOption=true, isCyberCrucibleUser, onApproveDealClick,
                                                   onRenewDealClick,onRejectDealClick,onCreateSubscription ,partnerDealId, fontAwesomeIcon="fa-duotone fa-calendar-clock",
                                                   sx = {width: 25, height: 25}, iconColor="black"}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if(isCyberCrucibleUser){
            setAnchorEl(event.currentTarget);
        }
    };
    const handleApproveDeal = () => {
        handleClose()
        onApproveDealClick && onApproveDealClick()
    };
    const handleCreateSubscriptionForDeal = () => {
        handleClose()
        onCreateSubscription && onCreateSubscription()
    };
    const handleRenewDeal = () => {
        handleClose()
        onRenewDealClick && onRenewDealClick()
    };
    const handleRejectDeal = () => {
        handleClose()
        onRejectDealClick && onRejectDealClick()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
                <div onClick={handleClick} className={"flex flex-row flex-nowrap gap-x-2 items-center border-2 border-slate-400 rounded-lg px-3 py-0.5 cursor-pointer"}>
                    <img className={`ccLogoCell ${isCyberCrucibleUser ? "" : "opacity-50"}`} src="/images/logo/CroppedC_CyberCrucibleLogo.svg" alt="" />
                    <IconButton
                        sx={sx}
                        className={`self-center object-contain ${isCyberCrucibleUser ? "" : "opacity-50"}`}
                        id={`basic-button-${partnerDealId}`}
                        disableRipple={true}
                        aria-controls={open ? `basic-menu-${partnerDealId}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FontAwesomeIcon
                            className={`object-contain`}
                            icon={fontAwesomeIcon}
                            size="xs"
                            color={iconColor}
                        />
                    </IconButton>
                </div>
            </Tooltip>
            <Menu
                id={`basic-menu-${partnerDealId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `basic-button-${partnerDealId}`,
                }}
            >
                <MenuItem disabled={hideApproveOption} onClick={handleApproveDeal}>Approve Deal</MenuItem>
                <MenuItem disabled={hideSubscriptionOption || (params && params.node.data.dealCompleted !== null && params.node.data.dealCompleted !== undefined)} onClick={handleCreateSubscriptionForDeal}>Complete Deal and Create Subscription</MenuItem>
                <MenuItem disabled={hideRenewOption} onClick={handleRenewDeal}>Renew Deal</MenuItem>
                <MenuItem disabled={hideRejectOption} onClick={handleRejectDeal}>Reject Deal</MenuItem>
            </Menu>
        </div>
    );
}

export const MuiDistributorsOnlyDealStatusLogoIconWithMenu = ({tooltipTitle, tooltipPlacement="bottom-start", followCursor = false, hideRenewOption=false,
                                                  hideApproveOption=false, hideRejectOption=false, isDistributorUser, onApproveDealClick,
                                                  onRenewDealClick,onRejectDealClick, partnerDealId, fontAwesomeIcon="fa-duotone fa-calendar-clock",
                                                  sx = {width: 25, height: 25}, iconColor="black"}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if(isDistributorUser){
            setAnchorEl(event.currentTarget);
        }
    };
    const handleApproveDeal = () => {
        handleClose()
        onApproveDealClick && onApproveDealClick()
    };
    const handleRenewDeal = () => {
        handleClose()
        onRenewDealClick && onRenewDealClick()
    };
    const handleRejectDeal = () => {
        handleClose()
        onRejectDealClick && onRejectDealClick()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
                <div onClick={handleClick} className={"flex flex-row flex-nowrap gap-x-2 items-center border-2 border-slate-400 rounded-lg px-3 py-0.5 cursor-pointer"}>
                    <IconButton
                        sx={sx}
                        className={`self-center object-contain ${isDistributorUser ? "" : "opacity-50"}`}
                        id={`basic-button-${partnerDealId}`}
                        disableRipple={true}
                        aria-controls={open ? `basic-menu-${partnerDealId}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FontAwesomeIcon
                            className={`object-contain`}
                            icon={"fa-duotone fa-chart-network"}
                            size="xs"
                        />
                    </IconButton>
                    <IconButton
                        sx={sx}
                        className={`self-center object-contain ${isDistributorUser ? "" : "opacity-50"}`}
                        id={`basic-button-${partnerDealId}`}
                        disableRipple={true}
                        aria-controls={open ? `basic-menu-${partnerDealId}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FontAwesomeIcon
                            className={`object-contain`}
                            icon={fontAwesomeIcon}
                            size="xs"
                            color={iconColor}
                        />
                    </IconButton>
                </div>
            </Tooltip>
            <Menu
                id={`basic-menu-${partnerDealId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `basic-button-${partnerDealId}`,
                }}
            >
                <MenuItem disabled={hideApproveOption} onClick={handleApproveDeal}>Approve Deal</MenuItem>
                <MenuItem disabled={hideRenewOption} onClick={handleRenewDeal}>Renew Deal</MenuItem>
                <MenuItem disabled={hideRejectOption} onClick={handleRejectDeal}>Reject Deal</MenuItem>
            </Menu>
        </div>
    );
}

export const MuiPartnerDealQuoteIconWithMenu = ({tooltipTitle, tooltipPlacement="bottom-start", followCursor = false, disableInternalPricingOptions=false,
                                                    isCyberCrucibleUser, partnerDealId, onQuoteOptionClickedFunction, params, onDownloadSignedQuoteClickedFunction,
                                                    sx = {width: 25, height: 25}}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleQuoteOptionClicked = (sendQuoteToAllGroupMembers, showInternalPricing) => {
        handleClose()
        onQuoteOptionClickedFunction && onQuoteOptionClickedFunction(sendQuoteToAllGroupMembers, showInternalPricing)
    };
    const handleDownloadSignedQuoteClicked = () => {
        if(params && params.node && params.node.data && params.node.data.dealCompleted !== null && params.node.data.dealCompleted !== undefined){
            handleClose()
            onDownloadSignedQuoteClickedFunction && onDownloadSignedQuoteClickedFunction()
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
                <IconButton
                    onClick={handleClick}
                    sx={sx}
                    className={`self-center object-contain`}
                    id={`quote-button-${partnerDealId}`}
                    disableRipple={true}
                    aria-controls={open ? `quote-menu-${partnerDealId}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <FontAwesomeIcon
                        className={`object-contain`}
                        icon={"fa-duotone fa-square-quote"}
                        size="1x"
                        color={"black"}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                id={`quote-menu-${partnerDealId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `quote-button-${partnerDealId}`,
                }}
            >
                <TreeView
                    aria-label="quote menu"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}

                >
                    <TreeItem nodeId="1" label="Send Quote to Myself">
                        <TreeItem disabled={disableInternalPricingOptions} onClick={() => {
                            //CC users can click send quote to themselves, but not all group members
                            if(!disableInternalPricingOptions){
                                handleQuoteOptionClicked(false, true)
                            }
                        }} nodeId="2" label="Send Internal Pricing" />
                        <TreeItem onClick={() => {handleQuoteOptionClicked(false, false)}} nodeId="3" label="Send Retail Pricing" />
                    </TreeItem>
                    <TreeItem disabled={isCyberCrucibleUser} nodeId="4" label="Send Quote to All Group Members">
                        <TreeItem disabled={disableInternalPricingOptions} onClick={() => {
                            if(!disableInternalPricingOptions){
                                handleQuoteOptionClicked(true, true)
                            }
                        }} nodeId="5" label="Send Internal Pricing" />
                        <TreeItem onClick={() => {handleQuoteOptionClicked(true, false)}} nodeId="6" label="Send Retail Pricing" />
                    </TreeItem>
                    <TreeItem disabled={params?.node?.data?.dealCompleted === null || params?.node?.data?.dealCompleted === undefined} onClick={() => {handleDownloadSignedQuoteClicked()}} nodeId="7" label={
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            <FontAwesomeIcon
                                className={`object-contain`}
                                icon={"fa-duotone fa-download"}
                                size="1x"
                                color={"black"}
                            />
                            Download Signed Quote
                        </div>
                    } />
                </TreeView>
            </Menu>
        </div>
    );
}
export const MuiPartnerDealPaymentIconWithMenu = ({tooltipTitle, tooltipPlacement="bottom-start", followCursor = false,
                                                    isCyberCrucibleUser, partnerDealId, onPaymentOptionClickedFunction, params, onResendInvoiceButtonClicked,
                                                    sx = {width: 25, height: 25}}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if(!isCyberCrucibleUser){
            setAnchorEl(event.currentTarget);
        }
    };
    const handleOptionClicked = (net30Clicked) => {
        handleClose()
        onPaymentOptionClickedFunction && onPaymentOptionClickedFunction(net30Clicked)
    };
    const handleResendInvoiceClicked = () => {
        handleClose()
        onResendInvoiceButtonClicked && onResendInvoiceButtonClicked()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function getTooltipTextForResendInvoice(){
        let tooltipText = "A request to sign and finalize the quote for this deal has not been made yet"
        if(params.node.data){
            if(params.node.data.billingCycle === monthlyBillingCycleText){
                //check monthly net 30 field net30MonthlyPaymentRequestedDate
                if(params.node.data.net30MonthlyPaymentRequestedDate){
                    tooltipText = `A request to sign and finalize the quote for this deal was made on ${convertDateToDateWithSessionTimeZone(params.node.data.net30MonthlyPaymentRequestedDate)}`
                }
            }
            else{
                //check yearly net 30 field
                if(params.node.data.net30YearlyPaymentRequestedDate){
                    tooltipText = `A request to sign and finalize the quote for this deal was made on ${convertDateToDateWithSessionTimeZone(params.node.data.net30YearlyPaymentRequestedDate)}`
                }
            }
        }
        return tooltipText
    }

    return (
        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
                <IconButton
                    onClick={handleClick}
                    sx={sx}
                    className={`self-center object-contain ${!isCyberCrucibleUser ? "" : "opacity-50"}`}
                    id={`payment-button-${partnerDealId}`}
                    disableRipple={true}
                    aria-controls={open ? `payment-menu-${partnerDealId}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <FontAwesomeIcon
                        className={`object-contain`}
                        icon={"fa-duotone fa-square-dollar"}
                        size="1x"
                        color={"black"}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                id={`payment-menu-${partnerDealId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `payment-button-${partnerDealId}`,
                }}
            >
                <MenuItem className={"flex flex-row flex-nowrap gap-x-1 items-center"} onClick={() => {handleOptionClicked(true)}}>
                    <div>
                        Sign & Finalize Quote
                    </div>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className={`object-contain`}
                                icon={"fa-duotone fa-calendar"}
                                size="sm"
                                color={"black"}
                            />
                        }
                        tooltipTitle={getTooltipTextForResendInvoice()}
                        tooltipPlacement={"bottom-start"}
                    />
                </MenuItem>
                <MenuItem
                    disabled={params?.node?.data?.dealCompleted === null || params?.node?.data?.dealCompleted === undefined}
                    className={"flex flex-row flex-nowrap gap-x-1 items-center"} onClick={() => {handleResendInvoiceClicked()}}>
                    <div>
                        Resend Invoice
                    </div>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className={`object-contain`}
                                icon={"fa-duotone fa-rotate-left"}
                                size="sm"
                                color={"black"}
                            />
                        }
                        tooltipTitle={`Click to resend the invoice for this deal`}
                        tooltipPlacement={"bottom-start"}
                    />
                </MenuItem>
            </Menu>
        </div>
    );
}

// For icons that have an action tied to it
export const MuiIconButtonWithTooltip = ({onClick, disabled = false, icon, tooltipTitle, tooltipPlacement, followCursor = false,
                                             sx = {color: "black", width: 25, height: 25}, enterDelayMillis=750
                                         }) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={enterDelayMillis} arrow>
            <IconButton
                sx={sx}
                disabled={disabled}
                className={"self-center object-contain"}
                onClick={() => {
                    onClick && onClick()
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export const MuiIconButtonWithTooltipAndBox = ({onClick, disabled = false, icon, tooltipTitle, tooltipPlacement, followCursor = false,
                                                   buttonsx={
                                                       "&:hover": {
                                                           backgroundColor: "white",
                                                           cursor: "pointer"
                                                       },
                                                       color: "#505050",
                                                       background: "#F3f3f3",
                                                       "&:disabled": {
                                                           backgroundColor: "#Fbfbfb",
                                                           cursor: "default",
                                                           color: "#C1c1c1"
                                                       },
                                                       borderRadius: "28px"
                                                   }, boxsx={ boxShadow: 7, borderRadius: '28px'}
                                               }) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box sx={boxsx}>
                <IconButton
                    sx={buttonsx}
                    disabled={disabled}
                    className={"self-center object-contain"}
                    onClick={() => {
                        onClick && onClick()
                    }}
                >
                    {icon}
                </IconButton>
            </Box>
        </Tooltip>
    )
}

// For icons that only have a tooltip for showing information
export const MuiIconWithTooltip = ({icon, tooltipTitle, tooltipPlacement, followCursor = false}) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box sx={{ width: 30, height: 30}}>
                {icon}
            </Box>
        </Tooltip>
    )
}

// For adding tooltip to text
export const MuiTextWithTooltip = ({text, tooltipTitle, tooltipPlacement, followCursor = false}) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box>
                {text}
            </Box>
        </Tooltip>
    )
}

//Autocomplete component not meant for groups, should be used for non group options like installer type for download agent modal
export const MuiAutocompleteNonGroupOptions=({options, value, onChange, label="Select", openOnFocus=true, disablePortal=true, disabled=false, size="small", multiple=false}) => {
    return (
        <ThemeProvider theme={autocompleteTheme}>
            <Autocomplete
                className="mt-3 "
                disablePortal={disablePortal}
                options={options}
                openOnFocus={openOnFocus}
                size={size}
                value={value}
                componentsProps={{ paper: { sx: { wordBreak: 'break-word' } } }} //wraps option input in dropdown
                disabled={disabled}
                multiple={multiple}
                renderInput={(params) => <TextField {...params} label={label} />}
                onChange={onChange}
                onKeyDown={(event) => {
                    if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                        event.preventDefault();
                    }
                }}
            />
        </ThemeProvider>
    )
}

export const MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput=({options, value, onInputChange, label="Select", openOnFocus=true, disablePortal=true, disabled=false, size="small"}) => {
    return (
        <ThemeProvider theme={autocompleteTheme}>
            <Autocomplete
                className="mt-3 "
                disablePortal={disablePortal}
                freeSolo //makes it so you can enter a value that is not in the list of options
                options={options}
                openOnFocus={openOnFocus}
                size={size}
                value={value}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} label={label} />}
                onInputChange={onInputChange} //Needs to be onInputChange instead of onChange because onChange does not trigger unless you click Enter in the text input
                onKeyDown={(event) => {
                    if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                        event.preventDefault();
                    }
                }}
            />
        </ThemeProvider>
    )
}

//Component is meant for group dropdown options with no option to create/add a new group
export const MuiAutocompleteForZenGroupsWithoutCreateGroupOption=({zenGroupDropdownOptionsList, value, onChange, label="Select", openOnFocus=true,
                                                                      disablePortal=true, disabled=false, size="small", fullWidth=false,
                                                                   className="mt-3", sx=null, multiple=false}) => {
   return (
       <ThemeProvider theme={autocompleteTheme}>
           <Autocomplete
               fullWidth={fullWidth}
               className={className}
               sx={sx}
               disablePortal={disablePortal}
               renderInput={(params) => <TextField {...params} label={label} />}
               renderOption={(props, option) => {
                   return (
                       <li {...props} key={option.value}>
                           {option.label}
                       </li>
                   );
               }}
               getOptionLabel={(option) => {
                   // Check if we have the label (group name) already
                   if(option && option.label){
                       return option.label
                   }
                   else if(option && option.value){ //If we don't have the label already but have the group id represented by option.value
                       let zenGroup = zenGroupDropdownOptionsList.find((group) => group.value === option.value)
                       return (zenGroup && zenGroup.label) ? zenGroup.label : ""
                   }
                   else if(option){ //If we don't have the label already and the group id is represented by the option variable itself and not option.value
                       let zenGroup = zenGroupDropdownOptionsList.find((group) => group.value === option)
                       return (zenGroup && zenGroup.label) ? zenGroup.label : ""
                   }
                   else{ //else return empty string but should not get here
                       return ""
                   }
               }}
               value={value || null} //Pass the value if not undefined else pass null instead of an undefined value
               options={zenGroupDropdownOptionsList}
               multiple={multiple}
               isOptionEqualToValue={(option, value) => {
                   if(value && value.value){ //If we have the groupId represented by value.value
                       return option?.value === value.value
                   }
                   else if(value){ //If we have the groupId represented by value and not value.value
                       return option?.value === value
                   }
                   else{ //default to false
                       return false
                   }
               }}
               openOnFocus={openOnFocus}
               size={size}
               disabled={disabled}
               onChange={onChange}
               onKeyDownCapture={(event) => {
                   if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                       event.preventDefault();
                   }
               }}
               /*onKeyDown={(event) => {
                   if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                       event.preventDefault();
                   }
               }}*/
           />
       </ThemeProvider>
   )
}

//setGroupListUnformattedHook is specific to download agent modal, if new group gets made this hook will be used to add new group to group list hook without groups formatted to value/label format since supplier of zenGroupDropdownOptionsList should handle this
export const MuiAutocompleteForZenGroupsWithCreateGroupOption=({zenGroupDropdownOptionsList, setGroupListUnformattedHook, value, setSelectedGroup, setIsLoading, label="Select", openOnFocus=true, disablePortal=true, disabled=false, size="small"}) => {
    const [newGroupModalOpen, setNewGroupModalOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState(null);

    return (
        <div>
            <ThemeProvider theme={autocompleteTheme}>
                <Autocomplete
                    className="mt-3 "
                    disablePortal={disablePortal}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    getOptionLabel={(option) => {
                        // Check if we have the label (group name) already
                        if(option && option.label){
                            return option.label
                        }
                        else if(option && option.value){ //If we don't have the label already but have the group id represented by option.value
                            let zenGroup = zenGroupDropdownOptionsList.find((group) => group.value === option.value)
                            return (zenGroup && zenGroup.label) ? zenGroup.label : ""
                        }
                        else if(option){ //If we don't have the label already and the group id is represented by the option variable itself and not option.value
                            let zenGroup = zenGroupDropdownOptionsList.find((group) => group.value === option)
                            return (zenGroup && zenGroup.label) ? zenGroup.label : ""
                        }
                        else{ //else return empty string but should not get here
                            return ""
                        }
                    }}
                    value={value || null} //Pass the value if not undefined else pass null instead of an undefined value
                    options={zenGroupDropdownOptionsList}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        );
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if(value && value.value){ //If we have the groupId represented by value.value
                            return option?.value === value.value
                        }
                        else if(value){ //If we have the groupId represented by value and not value.value
                            return option?.value === value
                        }
                        else{ //default to false
                            return false
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filter = createFilterOptions({
                            stringify: (option) => option.label ? option.label: "",
                        })
                        const { inputValue } = params;
                        const filtered = filter(options, params);
                        const isExisting = options.some((option) => inputValue?.toLowerCase() === option?.label?.toLowerCase());
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                value: params.inputValue,
                                label: `Quick Create Group: "${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    onChange={(event, newValue) => {
                        if(newValue === null || newValue === undefined){
                            setSelectedGroup(null)
                        }
                        else if (newValue.label && newValue.label.includes(`Quick Create Group: "`)) {
                            //Open new group modal
                            let groupName = newValue?.value?.trim()
                            setNewGroupName(groupName)
                            setNewGroupModalOpen(true)
                            //Reset selected group since they can change the name in group modal or close the modal, they'll have to select the new group after its created in modal
                            setSelectedGroup(null)
                        }
                        else {
                            setSelectedGroup(newValue?.value)
                        }
                    }}
                    openOnFocus={openOnFocus}
                    size={size}
                    disabled={disabled}
                    onKeyDown={(event) => {
                        if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                            event.preventDefault();
                        }
                    }}
                />
            </ThemeProvider>
            <CreateGroupModal
                isOpen={newGroupModalOpen} setIsOpen={setNewGroupModalOpen} setIsLoading={setIsLoading} defaultNewGroupName={newGroupName} setGroupListUnformattedHook={setGroupListUnformattedHook} setSelectedGroup={setSelectedGroup}
            />
        </div>
    )
}

export const MuiAgentLicenseAutoAssignLicenseIconWithMenu=({tooltipPlacement="bottom-start", followCursor = false, params,agentId, onClickFunction}) => {

    return (
        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
            <Tooltip title={<div className={"text-sm"}>{`${(params?.node?.data?.licenseManuallyRemovedByUser && params?.node?.data?.specialStatusMessage !== "uninstalled")
                ? "This agent will not automatically look for a new license the next time it calls into validate and must be manually assigned a license."
                : "This agent will automatically look for a new license the next time it calls into validate."} ${params?.node?.data?.specialStatusMessage === "uninstalled" ? "" : "Click to change this setting"}`}</div>}
                     slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
                <div onClick={() => {onClickFunction && onClickFunction()}}
                     className={`flex flex-row flex-nowrap gap-x-2 items-center border-2 border-slate-400 rounded-lg px-3 py-0.5 cursor-pointer 
                     ${params?.node?.data?.specialStatusMessage === "uninstalled" ? "opacity-50" : ""}`}>
                    <IconButton
                        sx={{width: 25, height: 25}}
                        className={`self-center object-contain`}
                        id={`autoAssignOptionAgent-${agentId}`}
                        disableRipple={true}
                    >
                        <FontAwesomeIcon
                            className={`object-contain`}
                            icon={`fa-duotone ${(params?.node?.data?.licenseManuallyRemovedByUser && params?.node?.data?.specialStatusMessage !== "uninstalled") ? "fa-circle-pause" : "fa-rotate"}`}
                            size="sm"
                            color={"black"}
                        />
                    </IconButton>
                    <IconButton
                        sx={{width: 25, height: 25}}
                        className={`self-center object-contain`}
                        id={`agentLicenseIconAgent-${agentId}`}
                        disableRipple={true}
                    >
                        <FontAwesomeIcon
                            className={`object-contain`}
                            icon={"fa-duotone fa-id-badge"}
                            size="sm"
                            color={"black"}
                        />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );
}